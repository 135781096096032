import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  useTheme,
  FormLabel,
  Modal,
  IconButton,
  Typography,
  Select,
  MenuItem,
  Stack,
  Chip,
} from "@mui/material";
import Dropdown from "../../../Components/Dropdown/Dropdown";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import {
  GetUserDetails,
  isValidEmail,
  isValidPhoneNumber,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import { deleteAPI, getAPI, updateAPI } from "../../../Services/Api";
import { toast } from "react-toastify";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../../Auth/Firebase/firebase";
import close from "../../../Assets/Images/cancel-circle-half-dot.png";
import deleteVec from "../../../Assets/Images/deleteVector.png";
import { EMPLOYEE_ROLE, EMPLOYER_ROLE } from "../../../Helpers/roles";
import { useTranslation } from "react-i18next";

const EditUserForm = ({ user, onBackClick, roles, isWWH }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [isFocused, setIsFocused] = useState(false);

  const { t } = useTranslation();

  // Default values for dropdowns
  const [name, setName] = useState(""); // Default to 'active'
  const [phoneNumber, setPhoneNumber] = useState(""); // Default to 'active'
  const [email, setEmail] = useState(""); // Default to 'active'
  const [role, setRole] = useState([]); // Default to 'user'
  const [gender, setGender] = useState("Male"); // Default to 'male'
  const [status, setStatus] = useState("Activated"); // Default to 'active'

  // const [roles, setRoles] = useState([]);
  const [error, setError] = useState({}); // Default to 'male'

  // useEffect(() => {
  //   fetchrole();
  // }, []);

  useEffect(() => {
    if (user) {
      setName(user.firstName);
      setEmail(user.email);
      setPhoneNumber(user.phoneNumber);
      setRole(user?.role?.map((ele) => ele._id));
      setGender(
        (user?.gender || "").charAt(0).toUpperCase() +
          (user?.gender || "").slice(1)
      );
      setStatus(user?.status || "");
    }
  }, [user]);

  const validateForm = () => {
    let isValid = true;
    let err = {};
    if (!name.trim()) {
      isValid = false;
      err = { ...err, name: t("plzEnterName") };
    }

    if (!email.trim()) {
      isValid = false;
      err = { ...err, email: t("plzEnterEmail") };
    } else if (!isValidEmail(email.trim())) {
      isValid = false;
      err = { ...err, email: t("plzEnterValEmail") };
    }

    if (role.length <= 0) {
      isValid = false;
      err = { ...err, role: t("plzSelectRole") };
    }

    if (!gender.trim()) {
      isValid = false;
      err = { ...err, gender: t("plzEnterGender") };
    }

    if (!status.trim()) {
      isValid = false;
      err = { ...err, status: t("plzEnterstatus") };
    }

    setError(err);
    return isValid;
  };

  // Handle input focus
  const handleFocus = () => {
    // Logic when input is focused
    validateForm();
  };

  // const fetchrole = async () => {
  //   LoaderShow();
  //   try {
  //     const token = GetUserDetails()?.token;
  //     const response = await getAPI("role/getAll", token);
  //     if (response?.statusCode == 200) {
  //       setRoles(
  //         response.data.map((ele) => ({ value: ele._id, label: ele.name }))
  //       );
  //     }
  //   } catch (err) {
  //     console.log(err);
  //     toast.error(t(err.message));
  //   }
  //   LoaderHide();
  // };

  // Sample options for dropdowns
  const genderOptions = [
    { value: "Male", label: "male" },
    { value: "Female", label: "female" },
    { value: "Other", label: "other" },
  ];

  const statusOptions = [
    { value: "Activated", label: "ActiveOne" },
    { value: "Suspended", label: "SusP" },
    { value: "Inactive", label: "Inactive" },
  ];

  const [open, setOpen] = useState(false);
  const handleOpen = (user) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmDelete = async () => {
    LoaderShow();
    try {
      const token = GetUserDetails()?.token;
      const data = {
        number: user?.phoneNumber,
      };
      if (token && data.number) {
        const response = await deleteAPI("user/delete-user", data, token);
        if (response.statusCode == 200) {
          if (user?.firebaseUID) {
            await deleteDoc(doc(db, "users", user.firebaseUID));
            const roomRef = collection(db, "rooms");
            const roomQuery = query(
              roomRef,
              where("userIds", "array-contains", user.firebaseUID)
            );
            const rooms = await getDocs(roomQuery);
            rooms.forEach(async (documentSnapshot) => {
              await deleteDoc(doc(db, "rooms", documentSnapshot.id));
            });
            const supportRef = collection(db, "supportChat");
            const supportQuery = query(
              supportRef,
              where("userId", "==", user.firebaseUID)
            );
            const supports = await getDocs(supportQuery);
            supports.forEach(async (documentSnapshot) => {
              await deleteDoc(doc(db, "supportChat", documentSnapshot.id));
            });
          }
          toast.success(t("toast32"));
          handleClose();
          onBackClick();
        }
      }
    } catch (err) {
      console.log(t(err.message));
      toast.error(t(err.message));
    }
    LoaderHide();
    handleClose();
  };

  const updateUser = async (ev) => {
    ev.preventDefault();
    if (!validateForm()) return;
    LoaderShow();
    const token = GetUserDetails()?.token;
    if (token) {
      try {
        let dataToSend;
        if (isWWH) {
          dataToSend = {
            firstName: name,
            userName: name,
            email: email,
            role: role,
            gender: gender,
            status: status,
          };
        } else {
          dataToSend = {
            status: status,
          };
        }
        const response = await updateAPI(
          `user/update/${user._id}`,
          dataToSend,
          token
        );
        if (response.statusCode === 200) {
          toast.success(t("toast33"));
          onBackClick();
        }
      } catch (err) {
        console.log(t(err.message));
        toast.error(t(err.message));
      }
    }
    LoaderHide();
  };

  return (
    <Box
      sx={{
        backgroundColor: "#FFFFFF",
        borderRadius: "30px",
        padding: 4,
        position: "relative",
      }}
    >
      <Box component="form" onSubmit={updateUser}>
        <Box sx={{ mb: 2 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: 2,
            }}
          >
            <Box sx={{ flex: 1 }}>
              <FormLabel
                sx={{ display: "block", mb: 1, color: "black", fontSize: 14 }}
              >
                {t("name")}
              </FormLabel>
              <TextField
                onChange={(ev) => setName(ev.target.value)}
                value={name}
                variant="outlined"
                sx={{
                  background: isFocused ? "#FFFFFF" : "transparent",
                  borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                }}
                fullWidth
                placeholder={t("name")}
                onFocus={handleFocus}
                onBlur={handleFocus}
                error={!!error.name}
                helperText={error.name}
                required={isWWH}
                disabled={!isWWH}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <FormLabel
                sx={{ display: "block", mb: 1, color: "black", fontSize: 14 }}
              >
                {t("Email")}
              </FormLabel>
              <TextField
                onChange={(ev) => setEmail(ev.target.value)}
                value={email}
                variant="outlined"
                sx={{
                  background: isFocused ? "#FFFFFF" : "transparent",
                  borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                }}
                fullWidth
                placeholder={t("Email")}
                onFocus={handleFocus}
                onBlur={handleFocus}
                error={!!error.email}
                helperText={error.email}
                required={isWWH}
                disabled={!isWWH}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              my: 2,
              flexDirection: isMobile ? "column" : "row",
              gap: 2,
            }}
          >
            <Box sx={{ flex: 1 }}>
              <FormLabel
                sx={{ display: "block", mb: 1, color: "black", fontSize: 14 }}
              >
                {t("Phone")}
              </FormLabel>
              <TextField
                onChange={(ev) => setPhoneNumber(ev.target.value)}
                value={phoneNumber}
                variant="outlined"
                sx={{
                  background: isFocused ? "#FFFFFF" : "transparent",
                  borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                }}
                fullWidth
                placeholder={t("Phone")}
                onFocus={handleFocus}
                onBlur={handleFocus}
                error={!!error.email}
                helperText={error.email}
                required={false}
                disabled={true}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <FormLabel
                sx={{ display: "block", mb: 1, color: "black", fontSize: 14 }}
              >
                {t("Gender")}
              </FormLabel>
              {/* <TextField
                onChange={(ev) => setGender(ev.target.value)}
                value={gender}
                variant="outlined"
                sx={{
                  background: isFocused ? "#FFFFFF" : "transparent",
                  borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                }}
                fullWidth
                placeholder="Gender"
                onFocus={handleFocus}
                onBlur={handleFocus}
                error={!!error.email}
                helperText={error.email}
                required={isWWH}
                disabled={!isWWH}
              /> */}
              <Select
                fullWidth
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                placeholder={t("Gender")}
                onFocus={handleFocus}
                onBlur={handleFocus}
                error={!!error.gender}
                helperText={error.gender}
                required={isWWH}
                disabled={!isWWH}
              >
                {genderOptions.map((ele) => (
                  <MenuItem value={ele.value} key={ele.value}>
                    {t(ele.label)}
                  </MenuItem>
                ))}
              </Select>
              {/* {error.gender && (
                <span
                  style={{
                    color: "#d32f2f",
                    fontSize: "0.75rem",
                    lineHeight: "1.66",
                    letterSpacing: "0.0333em",
                  }}
                >
                  {error.gender}
                </span>
              )} */}
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              my: 2,
              flexDirection: isMobile ? "column" : "row",
              gap: 2,
            }}
          >
            <Box sx={{ flex: 1 }}>
              <FormLabel
                sx={{ display: "block", mb: 1, color: "black", fontSize: 14 }}
              >
                {t("role")}
              </FormLabel>
              <Select
                multiple
                fullWidth
                value={role}
                onChange={(e) => {
                  setRole(e.target.value.filter((ele) => ele));
                }}
                placeholder={t("role")}
                onFocus={handleFocus}
                onBlur={handleFocus}
                error={!!error.role}
                helperText={error.role}
                required={isWWH}
                disabled={!isWWH}
                renderValue={(selected) => (
                  <Stack gap={1} direction="row" flexWrap="wrap">
                    {role?.map((value) => (
                      // <Chip key={value} label={"React"} />
                      <Chip
                        key={value}
                        label={
                          roles?.find((skill) => skill?.value === value)
                            ?.label || ""
                        }
                      />
                    ))}
                  </Stack>
                )}
              >
                {roles
                  ?.filter(
                    (ele) => ![EMPLOYEE_ROLE, EMPLOYER_ROLE].includes(ele.value)
                  )
                  .map((ele) => (
                    <MenuItem value={ele?.value} key={ele?.value}>
                      {ele?.label}
                    </MenuItem>
                  ))}
              </Select>
              {error.role && (
                <span
                  style={{
                    color: "#d32f2f",
                    fontSize: "0.75rem",
                    lineHeight: "1.66",
                    letterSpacing: "0.0333em",
                  }}
                >
                  {error.role}
                </span>
              )}
              {/* <Dropdown
                value={role}
                onChange={(e) => setRole(e.target.value)}
                options={roles}
                label="Role"
                onFocus={handleFocus}
                onBlur={handleFocus}
                required={isWWH}
                disabled={!isWWH}
              />
              {error.role && (
                <span
                  style={{
                    color: "#d32f2f",
                    fontSize: "0.75rem",
                    lineHeight: "1.66",
                    letterSpacing: "0.0333em",
                  }}
                >
                  {error.role}
                </span>
              )} */}
            </Box>
            <Box sx={{ flex: 1 }}>
              <FormLabel
                sx={{ display: "block", mb: 1, color: "black", fontSize: 14 }}
              >
                {t("Status")}
              </FormLabel>
              <Dropdown
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                options={statusOptions.map((ele) => ({
                  ...ele,
                  label: t(ele.label),
                }))}
                label={t("Status")}
                onFocus={handleFocus}
                onBlur={handleFocus}
                required
              />
              {error.status && (
                <span
                  style={{
                    color: "#d32f2f",
                    fontSize: "0.75rem",
                    lineHeight: "1.66",
                    letterSpacing: "0.0333em",
                  }}
                >
                  {error.status}
                </span>
              )}
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "", mt: 4 }}>
          <Button
            type="submit"
            sx={{
              textTransform: "capitalize",
              my: 2,
              bgcolor: "#0B3013",
              fontSize: 16,
              color: "#fff",
              borderRadius: 10,
              px: 4,
              py: 1,
              fontWeight: 400,
              "&:hover": {
                bgcolor: "#0B3013",
              },
            }}
            endIcon={<ArrowOutwardIcon color="#fff" />}
          >
            {t("Save")}
          </Button>
          <Button
            onClick={(ev) => setOpen(true)}
            sx={{
              textTransform: "capitalize",
              my: 2,
              mx: 2,
              bgcolor: "#0B3013",
              fontSize: 16,
              color: "#fff",
              borderRadius: 10,
              px: 4,
              py: 1,
              fontWeight: 400,
              "&:hover": {
                bgcolor: "#0B3013",
              },
            }}
            endIcon={<ArrowOutwardIcon color="#fff" />}
          >
            {t("DeleteAccountEmployee")}
          </Button>
        </Box>
      </Box>

      {/* Delete Confirmation Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            width: isMobile ? "90%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: 4,
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
            }}
          >
            <img src={close} alt="Close" />
          </IconButton>
          <Box>
            <Box
              sx={{
                display: "flex",
                margin: "auto",
                justifyContent: "center",
                py: 1,
              }}
            >
              <img className="deleteVec" src={deleteVec} alt="Delete Vector" />
            </Box>
            <Typography
              id="logout-modal-description"
              variant="h5"
              sx={{
                fontWeight: 700,
                fontSize: 32,
                textAlign: "center",
                py: 2,
                pb: 1,
              }}
              gutterBottom
            >
              {t("areYouSure")}
            </Typography>
            <Typography
              id="logout-modal-description"
              variant="body1"
              sx={{ fontSize: 18, textAlign: "center", display: "block" }}
              gutterBottom
            >
              {t("actionunDone")}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-around", mt: 4 }}>
            <Button
              variant="contained"
              onClick={handleClose}
              sx={{
                backgroundColor: "#0B3013!important",
                fontSize: 16,
                borderRadius: 20,
                textTransform: "capitalize",
                px: 4,
                py: 1,
              }}
            >
              {t("Cancel")}
            </Button>
            <Button
              sx={{
                backgroundColor: "#0B3013!important",
                fontSize: 16,
                borderRadius: 20,
                textTransform: "capitalize",
                px: 6,
                py: 1,
              }}
              variant="contained"
              onClick={handleConfirmDelete}
            >
              {t("Yes")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default EditUserForm;
