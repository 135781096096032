import React, { useEffect, useState } from "react";
import "./ReviewRatings.css";
import BrowseJobsBanner from "../../../Assets/Images/BrowseJobsBanner.png";
import AvatarJob from "../../../Assets/Images/BrowseJobsAvatar.png";
import chatIco from "../../../Assets/Images/ChatIco.png";
import dollarIco from "../../../Assets/Images/dollar-circle.png";
import indiaIco from "../../../Assets/Images/indiaFlag.png";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShareIcon from "@mui/icons-material/Share";
import MailIcon from "../../../Assets/Images/mail.png";
import DialIcon from "../../../Assets/Images/calling.png";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import loader from "../../../Assets/Images/loader.svg";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Rating,
  useMediaQuery,
  Avatar,
  Modal,
} from "@mui/material";

import StarIcon from "@mui/icons-material/Star";
// import SearchIcon from "@mui/icons-material/Search"; // Import SearchIcon properly

// import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
// import { Link } from "react-router-dom";
import { getAPI, postAPI } from "../../../Services/Api";
import { useTranslation } from "react-i18next";
import "../../../Localization/i18n";
import {
  getCountryCode,
  GetUserDetails,
  getUserDetailsFromAPI,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import {
  addDoc,
  collection,
  getDocs,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { db } from "../../../Auth/Firebase/firebase";
import { toast } from "react-toastify";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";

const labels = {
  0.5: "0.5",
  1: "1.0",
  1.5: "1.5",
  2: "2.0",
  2.5: "2.5",
  3: "3.0",
  3.5: "3.5",
  4: "4.0",
  4.5: "4.5",
  5: "5.0",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

export default function ReviewRatings() {
  const { t } = useTranslation();

  const [isSkillOpen, setIsSkillOpen] = useState(false);
  const [isProjectOpen, setIsProjectOpen] = useState(false);
  const [projectPage, setProjectPage] = useState(1);
  const [totalProjectPage, setTotalProjectPage] = useState(1);
  const [projects, setProjects] = useState([]);

  const [value, setValue] = React.useState(2);
  const [hover, setHover] = React.useState(-1);
  const isMobile = useMediaQuery("(max-width:768px    )");
  const location = useLocation();

  const navigate = useNavigate();

  const particularUser = location?.state?.user;

  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    getReviews();
  }, []);

  const getReviews = async () => {
    LoaderShow();
    try {
      const data = {
        userId: particularUser._id,
      };
      const response = await postAPI(
        "review/user-reviews",
        data,
        GetUserDetails().token
      );
      if (response.statusCode === 200) {
        setReviews(response.data);
      }
    } catch (error) {
      // toast.error(error.message)
    }
    LoaderHide();
  };

  const calculateDaysAgo = (dateString) => {
    const date = new Date(dateString);
    const currentDate = new Date();
    const diffInMs = currentDate - date;
    return Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  };

  const navigateToSendOffer = async (pageNumber = projectPage, status = 1) => {
    LoaderShow();
    try {
      var pn;
      if (pageNumber === undefined || pageNumber === "") {
        pn = 1;
      } else {
        pn = pageNumber;
      }

      var pageStatus;
      if (status === undefined || status === "") {
        pageStatus = 1;
      } else if (status === 0) {
        pageStatus = "0";
      } else {
        pageStatus = status;
      }

      const token = GetUserDetails().token;
      const dataToSend = { status: pageStatus };

      const response = await postAPI(
        `project/myproject?page=${pn}`,
        dataToSend,
        token
      );
      if (response.statusCode === 200) {
        setProjects(response?.data);
        const prjts = response.data.filter(
          (ele) => ele.status == "1" && !ele?.isAwarded
        );
        if (prjts.length > 0) {
          setIsProjectOpen(true);
          setProjects(prjts);
          setTotalProjectPage(response?.pagination?.totalPages);
          setProjectPage(response?.pagination?.currentPage);
        } else {
          toast.info(t("toast46"));
        }
        LoaderHide();
      }
    } catch (error) {
      LoaderHide();
      toast.error(error.message);
    }
  };

  const sendOffer = (project) => {
    navigate("/dashboard/employer/sendanoffer", {
      state: {
        projectData: project,
        userData: particularUser,
      },
    });
  };

  const createRoom = async (ev, user = particularUser) => {
    ev.stopPropagation();
    LoaderShow();
    try {
      const token = GetUserDetails().token;

      const userResponse = await getUserDetailsFromAPI();
      if (
        userResponse.statusCode == 200 &&
        userResponse?.data?.firebaseUID &&
        user?.firebaseUID
      ) {
        const roomRef = collection(db, "rooms");
        const roomQuery = query(
          roomRef,
          where(
            "userIds",
            "==",
            [userResponse.data.firebaseUID, user.firebaseUID].sort()
          )
        );
        const response = await getDocs(roomQuery);
        if (response.docs.length > 0) {
          navigate(
            `/dashboard/employer/message?room_id=${response.docs[0].id}`
          );
        } else {
          const data = await addDoc(collection(db, "rooms"), {
            userIds: [userResponse.data.firebaseUID, user.firebaseUID].sort(),
            createdAt: Timestamp.now(),
            imageUrl: null,
            metadata: null,
            name: null,
            type: "direct",
            updatedAt: Timestamp.now(),
            userRoles: null,
          });
          navigate(`/dashboard/employer/message?room_id=${data?.id}`);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <section className="BrowseJobsEmployee">
      <div
        id="hideloding"
        className="loding-display"
        style={{ display: "none" }}
      >
        <img src={loader} alt="loader-img" />
      </div>
      <Modal
        open={isSkillOpen}
        onClose={() => setIsSkillOpen(false)}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: 600,
            flexWrap: "wrap",
            borderRadius: "15px",
            backgroundColor: "#f8f9f8 !important",
            boxShadow: 24,
            textAlign: "center",
            display: "flex",
            flexDirection: "row",
            px: 4,
            py: 3,
            gap: "10px",
          }}
        >
          {particularUser?.skills?.map((skill, index) => (
            <Typography
              variant="h6"
              className="reviewBannerHead"
              sx={{
                color: "#fff",
                fontSize: 18,
                fontWeight: 500,
                bgcolor: "#0B3013",
                py: 1,
                px: 2,
                borderRadius: "20px",
              }}
            >
              {skill?.name}
            </Typography>
          ))}
        </Box>
      </Modal>
      <Modal
        open={isProjectOpen}
        onClose={() => setIsProjectOpen(false)}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            borderRadius: "15px",
            overflow: "auto",
            maxWidth: "80dvh",
            backgroundColor: "#f8f9f8 !important",
          }}
        >
          <Box sx={{ px: 4, py: 2, bgcolor: "#fff" }}>
            <h1>{t("PostedProjects")}</h1>
          </Box>
          <Box
            sx={{
              width: 600,
              flexWrap: "wrap",
              boxShadow: 24,
              textAlign: "center",
              display: "flex",
              px: 1,
              py: 3,
              gap: "10px",
              bgcolor: "#fff",
            }}
          >
            {projects?.map((project, index) => (
              <Box
                variant="h6"
                key={project._id}
                onClick={(ev) => sendOffer(project)}
                sx={{
                  fontSize: 18,
                  fontWeight: 500,
                  py: 1,
                  px: 2,
                  width: "100%",
                  cursor: "pointer",
                  textAlign: "left",
                }}
              >
                {project?.title}
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              bgcolor: "#fff",
              py: 1,
              px: 2,
              display: "flex",
              justifyContent: "end",
            }}
          >
            <CustomPagination
              count={totalProjectPage}
              page={projectPage}
              onChange={navigateToSendOffer}
            />
          </Box>
        </Box>
      </Modal>
      <Container maxWidth="xl">
        <Box sx={{ py: 2 }}>
          {isMobile ? (
            <Box
              sx={{
                backgroundColor: "#0B3013",
                p: 2,
                border: "1px solid #ccc",
                borderRadius: 4,
              }}
            >
              <Typography
                variant="h6"
                sx={{ color: "#fff", fontWeight: 500, mb: 1 }}
              >
                {particularUser?.firstName} {particularUser?.lastName}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <Rating
                  name="hover-feedback"
                  value={particularUser?.averageRating}
                  precision={0.5}
                  getLabelText={getLabelText}
                  // onChange={(event, newValue) => {
                  //   setValue(newValue);
                  // }}
                  // onChangeActive={(event, newHover) => {
                  //   setHover(newHover);
                  // }}
                  style={{
                    pointerEvents: "none",
                  }}
                  emptyIcon={
                    <StarIcon
                      sx={{ color: "#fff" }}
                      style={{ opacity: 1 }}
                      fontSize="inherit"
                    />
                  }
                />
                {particularUser?.averageRating && (
                  <Box sx={{ color: "#fff", ml: 1 }}>
                    {particularUser?.averageRating}
                  </Box>
                )}
              </Box>
              <Typography variant="body2" sx={{ mb: 1, color: "#fff" }}>
                {t("AwardProject")} {particularUser?.firstName}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <Avatar
                  src={particularUser?.profile}
                  alt={particularUser.firstName}
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: "50%",
                    marginRight: 8,
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{ fontWeight: 500, color: "#fff" }}
                >
                  {particularUser.email}
                </Typography>
              </Box>
              {/* <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <img
                  src={chatIco}
                  alt="chat"
                  style={{ width: 20, marginRight: 8, filter: "invert(1)" }}
                />
                <Typography variant="body2" sx={{ color: "#fff" }}>
                  200
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <img
                  src={dollarIco}
                  alt="dollar"
                  style={{ width: 20, marginRight: 8, filter: "invert(1)" }}
                />
                <Typography variant="body2" sx={{ color: "#fff" }}>
                  8.7
                </Typography>
              </Box> */}
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                {particularUser?.location &&
                getCountryCode(particularUser?.location) ? (
                  <img
                    width={25}
                    height={25}
                    src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${getCountryCode(
                      particularUser?.location
                    )}.svg`}
                    alt="chat"
                    style={{ marginRight: "7px" }}
                  />
                ) : (
                  ""
                )}
                <Typography variant="body2" sx={{ color: "#fff" }}>
                  {particularUser?.location ? particularUser?.location : ""}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", mb: 1 }}>
                {particularUser?.skills.map((skill, index) => (
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 500,
                      mr: 1,
                      color: "#fff",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {skill.name}
                  </Typography>
                ))}
              </Box>
              {/* <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <FavoriteBorderIcon
                  sx={{ color: "#fff", cursor: "pointer", mr: 1 }}
                />
                <ShareIcon sx={{ color: "#fff", cursor: "pointer" }} />
              </Box> */}
            </Box>
          ) : (
            <Box sx={{ pb: 10, pt: 5 }}>
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                }}
              >
                <img
                  src={BrowseJobsBanner}
                  className="bannerImageSize"
                  alt="BrowseJobsBanner"
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: isMobile ? "unset" : "50%",
                    bottom: isMobile ? "0" : "unset",
                    width: "100%",
                    maxWidth: isMobile ? "100%" : "50%",
                    left: 0,
                    transform: isMobile ? "unset" : "translateY(-50%)",
                    textAlign: isMobile ? "center" : "right",
                    color: "white",
                    paddingLeft: isMobile ? 2 : 12,
                    paddingBottom: isMobile ? 2 : "unset",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      position: "relative",
                      width: "100%",
                      flexDirection: isMobile ? "column" : "row",
                    }}
                  >
                    <Box sx={{ py: 2, position: "relative" }}>
                      <Typography
                        className="reviewRateTextResp"
                        variant={isMobile ? "h6" : "h4"}
                        sx={{ textAlign: "start" }}
                      >
                        {t("AwardProject")} {particularUser?.firstName}
                      </Typography>
                      {/* <Typography
                        variant="body1"
                        sx={{
                          textAlign: "start",
                          fontWeight: "normal",
                          display: "block",
                        }}
                      >
                        Lorem Ipsum
                      </Typography> */}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: isMobile ? "column" : "row",
                      alignItems: isMobile ? "center" : "flex-start",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Avatar
                        sx={{ width: 80, height: 80 }}
                        src={particularUser?.profile}
                        className=""
                        alt={particularUser?.firstName}
                      />
                    </Box>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", px: 2 }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: isMobile ? "column" : "row",
                          alignItems: isMobile ? "center" : "flex-start",
                        }}
                      >
                        <Typography
                          variant={isMobile ? "h6" : "h5"}
                          sx={{ color: "#fff", fontWeight: 400 }}
                        >
                          {particularUser?.email}
                        </Typography>
                      </Box>
                      <Box
                        sx={{ display: "flex", py: 1, alignItems: "center" }}
                      >
                        <Rating
                          name="hover-feedback"
                          value={particularUser?.averageRating}
                          precision={0.5}
                          getLabelText={getLabelText}
                          // onChange={(event, newValue) => {
                          //   setValue(newValue);
                          // }}
                          // onChangeActive={(event, newHover) => {
                          //   setHover(newHover);
                          // }}
                          style={{
                            pointerEvents: "none",
                          }}
                          emptyIcon={
                            <StarIcon
                              style={{ opacity: 1 }}
                              fontSize="inherit"
                            />
                          }
                        />
                        {particularUser?.averageRating && (
                          <Box sx={{ mx: 2 }}>
                            {particularUser?.averageRating}
                          </Box>
                        )}
                        {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={chatIco}
                            className="filterCommonWhite"
                            alt="chat"
                          />
                          <Typography
                            variant="body1"
                            sx={{ color: "#fff", px: 1 }}
                          >
                            200
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={dollarIco}
                            className="filterCommonWhite"
                            alt="chat"
                          />
                          <Typography
                            variant="body1"
                            sx={{ color: "#fff", px: 1 }}
                          >
                            8.7
                          </Typography>
                        </Box> */}
                        <Box
                          sx={{ display: "flex", alignItems: "center", px: 1 }}
                        >
                          {particularUser?.location &&
                          getCountryCode(particularUser?.location) ? (
                            <img
                              width={25}
                              height={25}
                              src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${getCountryCode(
                                particularUser?.location
                              )}.svg`}
                              alt="chat"
                            />
                          ) : (
                            ""
                          )}
                          <Typography
                            variant="body1"
                            sx={{ color: "#fff", px: 1 }}
                          >
                            {particularUser?.location}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          py: 0.5,
                          flexDirection: isMobile ? "column" : "row",
                          alignItems: isMobile ? "center" : "flex-start",
                        }}
                      >
                        {particularUser?.skills
                          ?.slice(0, 5)
                          ?.map((skill, index) => (
                            <Typography
                              variant="h6"
                              className="reviewBannerHead"
                              sx={{
                                color: "#fff",
                                fontSize: 18,
                                borderRight: isMobile ? "none" : 1,
                                fontWeight: 500,
                                px: 2,
                                pl: 0,
                                my: 1,
                                mr: 2,
                                maxWidth: "200px",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {skill?.name}
                            </Typography>
                          ))}
                        {particularUser?.skills?.length > 5 && (
                          <Button
                            onClick={(ev) => setIsSkillOpen(true)}
                            sx={{
                              textTransform: "capitalize",
                              color: "#fff",
                              fontSize: 18,
                              fontWeight: 500,
                              px: 2,
                              pl: 0,
                              mb: 1,
                              mt: 0,
                              mr: 2,
                              "&:hover": {
                                bgcolor: "transparent",
                              },
                              "&:active": {
                                bgcolor: "transparent",
                              },
                            }}
                          >
                            More...
                          </Button>
                        )}
                        {/* <Typography
                          variant="h6"
                          className="reviewBannerHead"
                          sx={{
                            color: "#fff",
                            fontSize: 18,
                            borderRight: isMobile ? "none" : 1,
                            fontWeight: 500,
                            px: 2,
                          }}
                        >
                          Illustrations Designer
                        </Typography>
                        <Typography
                                   className="reviewBannerHead"
                          variant="h6"
                          sx={{
                            color: "#fff",
                            fontSize: 18,
                            fontWeight: 500,
                            px: 2,
                          }}
                        >
                          Figma Expert
                        </Typography> */}
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {/* <Box
                  sx={{
                    position: "absolute",
                    right: 0,
                    zIndex: 9,
                    display: "flex",
                    alignItems: "center",
                    transform: isMobile ? "unset" : "translate(-50%, 100%)",
                  }}
                >
                  <FavoriteBorderIcon
                    sx={{ color: "#fff", cursor: "pointer" }}
                  />
                  <ShareIcon sx={{ px: 1, color: "#fff", cursor: "pointer" }} />
                </Box> */}
              </Box>
            </Box>
          )}
        </Box>

        <Box sx={{ pb: 10 }}>
          <Grid container spacing={3}>
            {" "}
            <Grid item xs={12} md={4} lg={8} xl={8}>
              <Box>
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    py: 3,
                    borderRadius: 10,
                    border: 1,
                    borderColor: "#e3e3e3",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      borderBottom: 1,
                      borderColor: "#dadbda",
                      p: 2.5,
                      pt: 0,
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: 500, color: "#000" }}
                      >
                        {t("EmployeeAbout")}
                      </Typography>
                    </Box>
                  </Box>

                  <Grid container spacing={2}>
                    {/* Left half */}
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          py: 2.5,
                          px: 2.5,
                        }}
                      >
                        <Typography
                          variant="p"
                          sx={{
                            fontWeight: 400,
                            color: "#222",
                            fontSize: 16,
                          }}
                        >
                          {particularUser?.aboutMe}
                        </Typography>
                        {/* <br />
                        <br />
                        <Typography
                          variant="p"
                          sx={{
                            fontWeight: 400,
                            color: "#222",
                            fontSize: 16,
                          }}
                        >
                          I'm seeking an experienced and efficient app developer
                          to create an innovative social networking application
                          for iOS platform. Details about the target audience
                          are currently confidential. Since the completion I'm
                          seeking an experienced and efficient app developer to
                          create more....
                        </Typography> */}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box
                  sx={{
                    backgroundColor: "#fff",
                    my: 4,
                    py: 3,
                    borderRadius: 10,
                    border: 1,
                    borderColor: "#e3e3e3",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      borderBottom: 1,
                      borderColor: "#dadbda",
                      p: 2.5,
                      pt: 0,
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: 500, color: "#000" }}
                      >
                        {t("Reviews")}
                      </Typography>
                    </Box>
                  </Box>

                  {reviews?.projects?.length === 0 && (
                    <>
                      <Box sx={{ px: 4, marginTop: "20px" }}>
                        <Typography
                          variant="p"
                          sx={{ color: "#222", fontWeight: 400 }}
                        >
                          {t("NoReviews")}
                        </Typography>
                      </Box>
                    </>
                  )}

                  {reviews?.projects?.map((review, index) =>
                    review.comments.map((comment, i) => (
                      <Grid container spacing={2} key={index + "_" + i}>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              py: 2,
                              borderBottom: 1,
                              borderColor: "#d6d6d6",
                            }}
                          >
                            <Box
                              sx={{
                                my: 2,
                                mt: 1,
                                p: 3,
                                pt: 0,
                                pb: 0,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <Box>
                                  <Typography
                                    variant="h6"
                                    sx={{ color: "#222" }}
                                  >
                                    {review?.title}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: "1"
                                  }}
                                >
                                  <Rating
                                    name="hover-feedback"
                                    value={review?.averageRating}
                                    precision={0.5}
                                    getLabelText={getLabelText}
                                    style={{ pointerEvents: "none" }}
                                    onChange={(event, newValue) => {
                                      setValue(newValue);
                                    }}
                                    onChangeActive={(event, newHover) => {
                                      setHover(newHover);
                                    }}
                                    emptyIcon={
                                      <StarIcon
                                        style={{ opacity: 0.55 }}
                                        fontSize="inherit"
                                      />
                                    }
                                  />
                                  {review?.averageRating !== null && (
                                    <Box sx={{ ml: 2 }}>
                                      {review?.averageRating}
                                    </Box>
                                  )}
                                </Box>
                              </div>
                              <Box>
                                <Typography variant="h6" sx={{ color: "#222" }}>
                                  ${review?.budget.max}-${review?.budget.min}
                                </Typography>
                              </Box>
                            </Box>
                            <Box sx={{ px: 4 }}>
                              <Typography
                                variant="p"
                                sx={{ color: "#222", fontWeight: 400 }}
                              >
                                {comment?.comment}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                p: 2.5,
                                pt: 0,
                                px: 4,
                                my: 2,
                                mb: 0,
                              }}
                              className="s flexWrapResp"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  flexWrap: "wrap",
                                }}
                              >
                                {review?.skillsRequired?.length &&
                                  review?.skillsRequired.map((skill, index) => (
                                    <Box
                                      key={index}
                                      sx={{
                                        width: "auto",
                                        height: "fit-content",
                                        maxWidth: "fit-content",
                                        backgroundColor: "#0B3013",
                                        color: "#fff",
                                        py: 1.2,
                                        my: 1,
                                        px: 3,
                                        borderRadius: 20,
                                      }}
                                    >
                                      {skill}
                                    </Box>
                                  ))}
                              </div>
                            </Box>
                            <Box
                              className="flexColumnRespo"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                px: 4,
                              }}
                            >
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Box>
                                  {/* <img
                                    src={indiaIco}
                                    className="filterCommonWhite"
                                    alt="chat"
                                  /> */}
                                  <Typography
                                    variant="p"
                                    sx={{ color: "#000", px: 1 }}
                                  >
                                    {comment?.reviewer?.firstName}{" "}
                                    {comment?.reviewer?.lastName}
                                  </Typography>
                                </Box>
                                <Box>
                                  {/* <Typography
                                    variant="p"
                                    sx={{ color: "#000", fontSize: 15, px: 1 }}
                                  >
                                    @gvmtech
                                  </Typography> */}
                                  <Typography
                                    variant="p"
                                    sx={{ color: "#000", fontSize: 13, px: 1 }}
                                  >
                                    {calculateDaysAgo(comment?.createdAt) > 0
                                      ? `${calculateDaysAgo(
                                          comment?.createdAt
                                        )} days ago`
                                      : "Today"}
                                  </Typography>
                                </Box>
                              </Box>

                              {/* <ShareIcon
                                sx={{
                                  px: 1,
                                  color: "#000",
                                  cursor: "pointer",
                                  ml: "auto",
                                }}
                              /> */}
                            </Box>
                          </Box>
                          {/* <Box
                            sx={{ py: 2, borderBottom: 1, borderColor: "#d6d6d6" }}
                          >
                            <Box
                              sx={{
                                my: 2,
                                mt: 1,
                                p: 3,
                                pt: 0,
                                pb: 0,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Rating
                                  name="hover-feedback"
                                  value={value}
                                  precision={0.5}
                                  getLabelText={getLabelText}
                                  onChange={(event, newValue) => {
                                    setValue(newValue);
                                  }}
                                  onChangeActive={(event, newHover) => {
                                    setHover(newHover);
                                  }}
                                  emptyIcon={
                                    <StarIcon
                                      style={{ opacity: 0.55 }}
                                      fontSize="inherit"
                                    />
                                  }
                                />
                                {value !== null && (
                                  <Box sx={{ ml: 2 }}>
                                    {labels[hover !== -1 ? hover : value]}
                                  </Box>
                                )}
                              </Box>

                              <Box>
                                <Typography variant="h6" sx={{ color: "#222" }}>
                                  $150 USD
                                </Typography>
                              </Box>
                            </Box>

                            <Box sx={{ px: 4 }}>
                              <Typography variant="h6" sx={{ color: "#222" }}>
                                Food Delviery Mobile App
                              </Typography>
                              <Typography
                                variant="p"
                                sx={{ color: "#222", fontWeight: 400 }}
                              >
                                Lorem Ipsum is simply dummy text of the printing and
                                typesetting industry. Lorem Ipsum has been the
                                industry's standard dummy. Lorem Ipsum is simply
                                dummy text of the printing and typesetting industry.
                              </Typography>
                            </Box>

                            <Box
                              className='skillSetChip'
                              sx={{
                                display: "flex",
                                flexWrap: 'wrap',
                                p: 2.5,
                                pt: 0,
                                px: 4,
                                my: 2,
                                mb: 0,
                              }}
                            >
                              <Box
                                sx={{
                                  width: "auto",
                                  height: "fit-content",
                                  maxWidth: "fit-content",
                                  backgroundColor: "#0B3013",
                                  color: "#fff",
                                  py: 1.2,
                                  px: 3,
                                  my: 1,
                                  borderRadius: 20,
                                }}
                              >
                                Figma
                              </Box>
                              <Box
                                sx={{
                                  width: "auto",
                                  height: "fit-content",
                                  maxWidth: "fit-content",
                                  backgroundColor: "#0B3013",
                                  color: "#fff",
                                  py: 1.2,
                                  px: 3,
                                  my: 1,
                                  mx: 1,
                                  borderRadius: 20,
                                }}
                              >
                                Sketch
                              </Box>
                              <Box
                                sx={{
                                  width: "auto",
                                  height: "fit-content",
                                  maxWidth: "fit-content",
                                  backgroundColor: "#0B3013",
                                  color: "#fff",
                                  py: 1.2,
                                  px: 3,
                                  my: 1,
                                  borderRadius: 20,
                                }}
                              >
                                HTML5
                              </Box>
                            </Box>
                            <Box
                              className='flexColumnRespo'
                              sx={{ display: "flex", alignItems: "center", px: 4 }}
                            >
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box>
                                  <img
                                    src={indiaIco}
                                    className="filterCommonWhite"
                                    alt="chat"
                                  />
                                  <Typography variant="p" sx={{ color: "#000", px: 1 }}>
                                    GVM Technologies
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography
                                    variant="p"
                                    sx={{ color: "#000", fontSize: 15, px: 1 }}
                                  >
                                    @gvmtech
                                  </Typography>
                                  <Typography
                                    variant="p"
                                    sx={{ color: "#000", fontSize: 13, px: 1 }}
                                  >
                                    5 Days ago
                                  </Typography>
                                </Box>
                              </Box>

                              <ShareIcon
                                sx={{
                                  px: 1,
                                  color: "#000",
                                  cursor: "pointer",
                                  ml: "auto",
                                }}
                              />
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              py: 2,
                              pb: 0,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <CustomPagination count={4} />
                          </Box> */}
                        </Grid>
                      </Grid>
                    ))
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item md={8} lg={4} xl={4}>
              <Box
                sx={{
                  backgroundColor: "#fff",
                  border: 1,
                  p: 3,
                  py: 2,
                  borderRadius: 10,
                  borderColor: "#e3e3e3",
                }}
              >
                <Box sx={{ py: 2 }}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: 500, color: "#000" }}
                  >
                    {t("AwardEmployee")}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="p"
                    sx={{ color: "#222", fontWeight: 400 }}
                  >
                    {t("AwardData")}
                  </Typography>
                  <Box sx={{ display: "flex", py: 1.5, alignItems: "center" }}>
                    <Typography variant="h6" sx={{ color: "#000" }}>
                      ${particularUser?.perHourAmount}
                    </Typography>
                    <Typography
                      variant="p"
                      sx={{ color: "#222", px: 1, fontWeight: 300 }}
                    >
                      {t("perHour")}{" "}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      backgroundColor: "#0B3013",
                      color: "#fff",
                      fontSize: 15,
                      px: 4,
                      py: 1,
                      borderRadius: 10,
                      my: 1,
                      mx: 10,
                      "&:hover": {
                        backgroundColor: "#0B3013", // Keep the same color as default
                      },
                    }}
                    onClick={() => navigateToSendOffer()}
                    // component={Link}
                    // to="/sendanoffer"
                  >
                    {t("Award")}
                  </Button>
                  <Button
                    onClick={(ev) => createRoom(ev)}
                    sx={{
                      textTransform: "capitalize",
                      borderColor: "#0B3013",
                      border: 1,
                      color: "#000",
                      fontSize: 15,
                      px: 4,
                      py: 1,
                      borderRadius: 10,
                      my: 1,
                      mx: 10,
                    }}
                  >
                    {t("Chat")}
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  backgroundColor: "#fff",
                  border: 1,
                  my: 4,
                  p: 3,
                  py: 2,
                  borderRadius: 10,
                  borderColor: "#e3e3e3",
                }}
              >
                <Box sx={{ py: 1.5, borderBottom: 1, borderColor: "#d6d6d6" }}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: 500, color: "#000" }}
                  >
                    {t("Verification")}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", py: 2 }}>
                  {particularUser?.isEmailVerified ? (
                    <>
                      <img src={MailIcon} alt="df" />
                      <Typography sx={{ px: 2 }}>
                        {t("emailVerified")}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <CancelScheduleSendIcon sx={{ color: "#239f40" }} />
                      <Typography sx={{ px: 2 }}>
                        {t("emailNotVerified")}
                      </Typography>
                    </>
                  )}
                </Box>
                <Box sx={{ display: "flex" }}>
                  {/* {particularUser?.isPhoneNumberVerified ? ( */}
                  <>
                    <img src={DialIcon} alt="df" />
                    <Typography sx={{ px: 2 }}>{t("phoneVerified")}</Typography>
                  </>
                  {/* ) : (
                    <>
                      <Typography sx={{ px: 2 }}>
                        Phone number not verified
                      </Typography>
                    </>
                  )} */}
                </Box>
              </Box>
              <Box
                sx={{
                  backgroundColor: "#fff",
                  border: 1,
                  p: 3,
                  py: 2,
                  borderRadius: 10,
                  borderColor: "#e3e3e3",
                }}
              >
                <Box sx={{ py: 1.5, borderBottom: 1, borderColor: "#e3e3e3" }}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: 500, color: "#000" }}
                  >
                    {t("TopSkills")}
                  </Typography>
                </Box>
                <Box
                  className="flexWrapResp"
                  sx={{ display: "flex", flexWrap: "wrap", py: 2 }}
                >
                  {particularUser?.skills?.map((skill, index) => (
                    <Box
                      sx={{
                        width: "auto",
                        height: "fit-content",
                        maxWidth: "fit-content",
                        backgroundColor: "#0B3013",
                        color: "#fff",
                        py: 1.2,
                        px: 3,
                        m: 0.5,
                        borderRadius: 20,
                      }}
                    >
                      {skill.name}
                    </Box>
                  ))}
                  {/* <Box
                    sx={{
                      width: "auto",
                      height: "fit-content",
                      maxWidth: "fit-content",
                      backgroundColor: "#0B3013",
                      color: "#fff",
                      py: 1.2,
                      px: 3,
                      m: 0.5,
                      borderRadius: 20,
                    }}
                  >
                    Photoshop
                  </Box>
                  <Box
                    sx={{
                      width: "auto",
                      height: "fit-content",
                      maxWidth: "fit-content",
                      backgroundColor: "#0B3013",
                      color: "#fff",
                      py: 1.2,
                      px: 3,
                      m: 0.5,
                      borderRadius: 20,
                    }}
                  >
                    UI/UX Design
                  </Box>
                  <Box
                    sx={{
                      width: "auto",
                      height: "fit-content",
                      maxWidth: "fit-content",
                      backgroundColor: "#0B3013",
                      color: "#fff",
                      py: 1.2,
                      m: 0.5,
                      px: 3,
                      borderRadius: 20,
                    }}
                  >
                    Graphic Design
                  </Box> */}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </section>
  );
}
