import React, { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/system";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CodeIcon from "@mui/icons-material/Code";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import DeleteIcon from "@mui/icons-material/Delete";
import EmailIcon from "@mui/icons-material/Email";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/material";
import OTPModal from "./OTPModal/OTPModal";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
// import { TextareaAutosize } from '@mui/material';
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import arrowCross from "../../../Assets/Images/arrow-up-right-01-round.png";
import vecNoimag from "../../../Assets/Images/App.png";
import OtpInput from "react-otp-input";
import AccountBalanceWalletRoundedIcon from "@mui/icons-material/AccountBalanceWalletRounded";
import "./EmployeeProfile.css";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  Avatar,
  FormLabel,
  Button,
  TextField,
  IconButton,
  InputAdornment,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Chip,
  OutlinedInput,
  Stack,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Dropdown from "../../../Components/Dropdown/Dropdown";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { deleteAPI, getAPI, postAPI, updateAPI } from "../../../Services/Api";
import {
  GetUserDetails,
  LoaderHide,
  LoaderShow,
  getUserDetailsFromAPI,
  hasSpecialCharacter,
  isValidEmail,
  isValidName,
  isValidPhoneNumber,
  isValidUSDTAddress,
} from "../../../Helpers/Utility";
import loader from "../../../Assets/Images/loader.svg";
import { toast } from "react-toastify";
import { countries } from "../../../Helpers/common_constants";
import { useNavigate } from "react-router-dom";
import OTPInput from "react-otp-input";
import { useTranslation } from "react-i18next";
import "../../../Localization/i18n";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  Timestamp,
  where,
} from "firebase/firestore";
import { auth, db } from "../../../Auth/Firebase/firebase";
import CustomCaretIcon from "../../EmployeerDashboardPages/EmployeerCreateProject/CustomCaretIcon";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import BalanceWithdrawDialog from "../../../Components/BalanceWithdrawDialog/BalanceWithdrawDialog";

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${
    theme.palette.mode === "dark" ? grey[900] : grey[50]
  };

  &:hover {
    border-color: ${blue[400]};
  }


  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

const StyledTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  fontFamily: "inherit !important",
  "& .MuiTabs-indicator": {
    backgroundColor: "#0B3013", // Change background color of active tab
  },
});

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  fontFamily: "inherit !important",
  minWidth: 0,
  marginRight: theme.spacing(4),
  "& .MuiTab-wrapper": {
    flexDirection: "row",
    gap: "8px",
    alignItems: "center", // Align icon and text vertically
  },
  "&.Mui-selected": {
    color: "#0B3013", // Change text color when tab is active
    "& svg": {
      fill: "#0B3013", // Change icon color when tab is active
    },
  },
}));

export default function EmployeeProfile() {
  const [value, setValue] = React.useState(0);
  const [avatar, setAvatar] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [setDepartment] = useState("");
  const [hourly] = useState("");
  const [specialization] = useState("");
  const [type] = useState("");
  const [language] = useState("");
  const [languageLevel] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [country] = useState("");
  const [city] = useState("");
  const [gender] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t, i18n } = useTranslation();

  const [isCategoryMenuOpen, setIsCategoryMenuOpen] = useState(false); // State for category menu open/close
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categoryError, setCategoryError] = useState("");
  const [projectData, setProjectData] = useState([]);

  // Define dropdown options

  const [data, setData] = React.useState({});
  const [withdrawOpen, setWithdrawOpen] = React.useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const token = GetUserDetails()?.token;
      if (token) {
        const res = await getAPI("fee/getAll", token);
        if (res?.statusCode == 200) {
          setData(res.data.find((ele) => ele.type == "employee"));
        }
      }
    } catch (err) {
      console.log(t(err.message));
      toast.error(t(err.message));
    }
  };

  const navigate = useNavigate();

  const hourlyOptions = [
    { value: "Hourly Rate", label: "Hourly Rate" },
    { value: "Fixed Price", label: "Fixed Price" },
  ];

  const specializationOptions = [
    { value: "demo", label: "demo" },
    { value: "demo1", label: "demo2" },
  ];

  const typeOptions = [
    { value: "demo", label: "demo" },
    { value: "demo1", label: "demo2" },
  ];

  const languageOptions = [
    { value: "demo", label: "demo" },
    { value: "demo1", label: "demo2" },
  ];

  const languageLevelOptions = [
    { value: "demo", label: "demo" },
    { value: "demo1", label: "demo2" },
  ];

  const countryOptions = [
    { value: "usa", label: "USA" },
    { value: "uk", label: "UK" },
    { value: "canada", label: "Canada" },
  ];

  const cityOptions = [
    { value: "newyork", label: "New York" },
    { value: "london", label: "London" },
    { value: "toronto", label: "Toronto" },
  ];

  const genderOptions = [
    { value: "Male", label: "male" },
    { value: "Female", label: "female" },
    { value: "Other", label: "other" },
  ];

  const handleFocus = () => {
    setIsFocused(false);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOtpSubmit = (otp) => {
    // Handle OTP submission logic here
    setIsModalOpen(false); // Close modal after submission
  };

  // Function to handle avatar upload
  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setAvatar(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
  };
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleToggleOldPasswordVisibility = () =>
    setShowOldPassword(!showOldPassword);
  const handleToggleNewPasswordVisibility = () =>
    setShowNewPassword(!showNewPassword);
  const handleToggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const renderInput = ({ onChange, onBlur, onFocus }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <PhoneInput
        placeholder="0000000000"
        country={"us"}
        value={phoneNumber}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        inputStyle={{
          background: isFocused ? "#FFFFFF" : "transparent",
          borderColor: isFocused ? "#0B3013" : "#E0E0E0",
          boxShadow: isFocused ? "0px 4px 10px 3px rgba(0,0,0,0.11)" : "none",
          width: "100%",
          padding: "12px 16px",
          borderRadius: "4px",
          outline: "none",
          boxSizing: "border-box",
          fontSize: "16px",
        }}
        containerStyle={{ width: "100%" }}
        inputProps={{ autoFocus: false }}
      />
      <span style={{ marginLeft: "5px", fontSize: "16px" }}>
        {phoneNumber ? `(+${phoneNumber.replace(/\D/g, "")})` : ""}
      </span>
    </div>
  );

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  // const [error, setError] = useState('');
  const [oldPasswordError, setOldPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [errors, setErrors] = useState({});

  const validateOldPassword = () => {
    if (!oldPassword) {
      setOldPasswordError("Old Password is required");
    } else {
      setOldPasswordError("");
    }
  };

  // Validation function for new password
  const validateNewPassword = () => {
    if (!newPassword) {
      setNewPasswordError("New Password is required");
    } else if (newPassword.length < 6) {
      setNewPasswordError("New Password must be at least 6 characters long");
    } else {
      setNewPasswordError("");
    }
  };

  // Validation function for confirm password
  const validateConfirmPassword = () => {
    if (!confirmPassword) {
      setConfirmPasswordError("Confirm Password is required");
    } else if (newPassword !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
    } else {
      setConfirmPasswordError("");
    }
  };

  const validate = () => {
    const errors = {};

    if (!oldPassword.trim()) {
      errors.oldPassword = t("PasswordIsRequired");
    } else if (oldPassword.length < 8 || !hasSpecialCharacter(oldPassword)) {
      errors.oldPassword = t("PassWordMustBe8Characters");
    }

    if (!newPassword.trim()) {
      errors.newPassword = t("NewPasswodRequired");
    } else if (newPassword.length < 8 || !hasSpecialCharacter(newPassword)) {
      errors.newPassword = t("PassWordMustBe8Characters");
    }

    if (!confirmPassword.trim()) {
      errors.confirmPassword = t("ConfirmPasswordRequired");
    } else if (
      confirmPassword.length < 8 ||
      !hasSpecialCharacter(confirmPassword)
    ) {
      errors.confirmPassword = t("PassWordMustBe8Characters");
    }

    if (newPassword !== confirmPassword) {
      errors.confirmPassword = t("PassWordNotMatch");
    }

    // if (!oldPassword) {
    //   errors.oldPassword = 'Old password is required';
    // }
    // if (!newPassword) {
    //   errors.newPassword = 'New password is required';
    // } else if (newPassword.length < 6) {
    //   errors.newPassword = 'New password must be at least 6 characters';
    // }
    // if (newPassword !== confirmPassword) {
    //   errors.confirmPassword = 'Passwords do not match';
    // }
    return errors;
  };

  const handleSubmitChangePassword = async (e) => {
    LoaderShow();
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      try {
        LoaderShow();
        const { token, userId } = GetUserDetails();
        const dataToSend = {
          userID: userId,
          oldPassword: oldPassword,
          newPassword: newPassword,
        };
        const response = await postAPI(
          "user/change-password",
          dataToSend,
          token
        );
        if (response.statusCode === 200) {
          LoaderHide();
          getUserDetails();
          toast.success(t(response?.message));
          setOldPassword("");
          setNewPassword("");
          setConfirmPassword("");
          setValue(0);
        } else {
          LoaderHide();
        }
      } catch (error) {
        LoaderHide();
        console.error("Failed to post data:", error.message);
      }
    }
    LoaderHide();
  };

  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    gender: "",
    country: "",
    state: "",
    language: "",
    languageLevel: "",
    location: "",
    rateType: "",
    aboutMe: "",
    perHourAmount: 30,
    skills: [],
  });

  useEffect(() => {
    validateForm();
  }, [userDetails]);

  const [validationErrors, setValidationErrors] = useState({});

  const skillstoset = [
    {
      _id: "6669354adcd439227064ef87",
      name: "React Js",
    },
    {
      _id: "66693577dcd439227064ef90",
      name: "Node js",
    },
    {
      _id: "66693539dcd439227064ef84",
      name: "JavaScript",
    },
  ];

  // const skillstoset = userDetails?.skills
  const userSelectedSkills = userDetails?.skills?.map((skill) => skill?.name);

  const [selectedSkills, setSelectedSkills] = useState([]);
  const [skillError, setSkillError] = useState(""); // State for skill validation error
  // const [selectedSkills, setSelectedSkills] = useState([]); // Initialize with "UX Design"

  useEffect(() => {
    getUserDetails();
    GetSkills();
  }, []);

  const [timer, setTimer] = useState(120); // Initial timer value in seconds
  const [resendDisabled, setResendDisabled] = useState(true); // Resend button disabled state
  const [appVerifier, setAppVerifier] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false); // State for registration section visibility
  const [confirmationResult, setConfirmationResult] = useState(null);

  useEffect(() => {
    let intervalId;
    if (isRegistering) {
      if (timer > 0) {
        intervalId = setInterval(() => {
          setTimer((prevTimer) => prevTimer - 1);
        }, 1000);
      } else {
        setResendDisabled(false); // Enable resend button when timer reaches 0
        clearInterval(intervalId);
      }
      return () => clearInterval(intervalId);
    }
  }, [timer, isRegistering]);

  // Define dropdown options

  const handleResendOtp = async () => {
    await handleSignInEmployer();
    setTimer(120);
    setResendDisabled(true);
  };

  const [phoneNumberError, setPhoneNumberError] = useState("");

  const getPhoneNumberExistance = async () => {
    if (!phoneNumber) {
      setPhoneNumberError(t("PleaseEnterPhoneNumber"));
    } else if (!isValidPhoneNumber(phoneNumber)) {
      setPhoneNumberError(t("PleaseEnterValidNumber"));
    } else {
      const dataToSend = {
        phoneNumber: `+${phoneNumber}`,
        role: "employer",
      };
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/user/checkExistence`,
          dataToSend
        );
        return response;
      } catch (error) {
        if (error.response) {
          return error.response.data;
        } else if (error.request) {
          console.error("No response received from the server");
        } else {
          console.error("Error:", error.message);
        }
      }
    }
  };

  const handleSignInEmployer = async () => {
    LoaderShow();
    const pnoneNumberExists = await getPhoneNumberExistance();

    if (pnoneNumberExists?.userStatus == "Suspended") {
      toast.error(t("toast6"));
      return;
    }

    if (pnoneNumberExists?.userStatus == "Inactive") {
      toast.error(t("toast7"));
      return;
    }

    if (
      pnoneNumberExists?.message === "Phone number already exists" ||
      pnoneNumberExists?.message ===
        "User already registered with a different role"
    ) {
      LoaderShow();
      if (!phoneNumber) {
        setPhoneNumberError(t("PleaseEnterPhoneNumber"));
      } else if (!isValidPhoneNumber(phoneNumber)) {
        setPhoneNumberError(t("PleaseEnterValidNumber"));
      } else {
        const dataToSend = {
          phoneNumber: `+${phoneNumber}`,
          role: "employer",
        };

        try {
          // before sigin login code
          // const response = await postAPI('auth/send-otp', dataToSend);

          // if (response.statusCode === 200) {
          //   LoaderHide()
          //   localStorage.setItem("mobile", response.otp)
          //   localStorage.setItem("phoneNumber", phoneNumber)
          //   setIsRegistering(true); // Toggle to display registration section
          //   toast.success(t(response.message));
          //   setPhoneNumberError('');

          // } else {
          //   toast.error(t(response.message));
          //   LoaderHide()

          // }

          // firebase login
          LoaderShow();
          let newAppVerifier;
          if (!appVerifier) {
            newAppVerifier = new RecaptchaVerifier(
              auth,
              "recaptcha-container",
              {
                size: "invisible",
                callback: (response) => {
                  // reCAPTCHA solved - will proceed with submit function
                },
                "expired-callback": () => {
                  // Response expired. Ask user to solve reCAPTCHA again.
                },
              }
            );
            setAppVerifier(newAppVerifier);
          }
          LoaderShow();

          // Old Promise.race method

          // const timeoutPromise = new Promise((_, reject) => {
          //   setTimeout(() => {
          //     reject(new Error("Timeout: OTP request took too long."));
          //   }, 10000); // 10 seconds timeout
          // });

          // const response = await signInWithPhoneNumber(auth, "+" + phoneNumber, appVerifier)
          // const response = await Promise.race([
          //   signInWithPhoneNumber(auth, `+${phoneNumber}`, appVerifier),
          //   timeoutPromise,
          // ]);
          const response = await signInWithPhoneNumber(
            auth,
            `+${phoneNumber}`,
            appVerifier || newAppVerifier
          );

          // console.log("response======", response);
          LoaderShow();

          if (response !== undefined) {
            LoaderHide();
            localStorage.setItem("mobile", response.otp);
            localStorage.setItem("phoneNumber", phoneNumber);
            setIsRegistering(true); // Toggle to display registration section
            // toast.success("OTP sent successfully");
            setPhoneNumberError("");
            setConfirmationResult(response);
          }
        } catch (error) {
          if (error.message.includes("auth/too")) {
            toast.error(t("toast10"));
          } else if (error.message.includes("invalid")) {
            toast.error(t("toast8"));
          } else if (error.message.includes("Firebase")) {
            toast.error(t("toast9"));
          } else {
            toast.error(error.message);
          }
          LoaderHide();
        }
      }
    } else {
      toast.error(t("toast11"));
      LoaderHide();
    }
    LoaderHide();
  };

  const handleVerifyNumberOTP = async () => {
    LoaderShow();
    // Validate OTP here
    // const validOtp = localStorage.getItem("mobile")
    const phoneNumber = localStorage.getItem("phoneNumber");
    if (!otp) {
      setOtpError(t("PleaseEnterOpt"));
    } else if (otp.length !== 6) {
      setOtpError(t("OTP6Digit"));
    } else {
      setOtpError("");
      const dataToSend = {
        // otp: otp,
        phoneNumber: `+${phoneNumber}`,
        userOTP: otp,
      };

      try {
        LoaderShow();

        // const response = await postAPI('auth/verify-otp', dataToSend);

        // if (response.statusCode === 200) {

        //   const data = {
        //     phoneNumber: `+${phoneNumber}`,
        //   }

        //   const responselogin = await postAPI("user/employer/login", data);
        //   console.log("Post response:", response);
        //   if (responselogin.statusCode === 200) {
        //     LoaderHide()
        //     toast.success(responselogin.message);
        //     setBrowseModalOpen(true);
        //     handleClose();
        //     // navigate("/services");
        //     // window.location.reload(true)
        //     var loginDetails = responselogin.data;
        //     var objLoginData = {
        //       userId: loginDetails._id,
        //       token: responselogin.token,
        //       role: loginDetails.role,
        //       userProfile: loginDetails.profile,
        //       firstName: loginDetails.firstName,
        //       phoneNumber: loginDetails.phoneNumber,
        //     };
        //     localStorage.setItem("loginData", JSON.stringify(objLoginData));
        //     handleClose();
        //   } else {
        //     toast.error("login failed");
        //     LoaderHide()
        //   }
        // } else {
        //   toast.error("Invalid OTP",);
        //   LoaderHide()
        // }

        const response = await confirmationResult?.confirm(otp);

        if (response?.user !== undefined) {
          const data = {
            number: `+${phoneNumber}`,
          };
          const { token } = GetUserDetails();
          const responselogin = await deleteAPI("user/delete", data, token);
          if (responselogin.statusCode === 200) {
            await deleteDoc(doc(db, "users", userDetails.firebaseUID));
            const roomRef = collection(db, "rooms");
            const roomQuery = query(
              roomRef,
              where("userIds", "array-contains", userDetails?.firebaseUID)
            );
            const rooms = await getDocs(roomQuery);
            rooms.forEach(async (documentSnapshot) => {
              await deleteDoc(doc(db, "rooms", documentSnapshot.id));
            });
            const supportRef = collection(db, "supportChat");
            const supportQuery = query(
              supportRef,
              where("userId", "==", userDetails?.firebaseUID)
            );
            const supports = await getDocs(supportQuery);
            supports.forEach(async (documentSnapshot) => {
              await deleteDoc(doc(db, "supportChat", documentSnapshot.id));
            });
            toast.success(responselogin.message);
            navigate("/home");
            localStorage.removeItem("loginData");
            localStorage.removeItem("notif-token");
          } else {
            toast.error(t("toast29"));
          }
        } else {
          toast.error(t("toast13"));
          LoaderHide();
        }
      } catch (error) {
        LoaderHide();
        if (error.message.includes("invalid")) {
          toast.error(t("toast13"));
          return;
        }
        if (error.message.includes("code-expired")) {
          toast.error(t("toast14"));
          return;
        }
        if (error.message.includes("Firebase")) {
          toast.error(t("toast9"));
          return;
        }
        toast.error(error.message);
      }
    }
    LoaderHide();
  };

  useEffect(() => {
    setSelectedSkills(userDetails.skills.map((skill) => skill.name));
  }, [userDetails]);

  const skills = useMemo(() => {
    if (!projectData?.categories || !selectedCategories?.length) return [];
    let arr = [];
    selectedCategories.map((category) => {
      arr = [
        ...arr,
        ...(projectData.categories.find((ele) => ele._id == category)?.skills ||
          []),
      ];
    });
    return arr;
  }, [selectedCategories, projectData]);

  const GetSkills = async () => {
    try {
      const response = await getAPI("project/dropdown");
      if (response.statusCode === 200) {
        setProjectData(response.data);
      } else {
        console.log("error ");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const suggestedSkills = useMemo(() => skills?.map((e) => e?.name), [skills]);

  useEffect(() => {
    setSelectedSkills((skills) =>
      skills.filter((ele) => suggestedSkills.includes(ele))
    );
  }, [suggestedSkills]);

  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    setSelectedSkills(userDetails.skills.map((skill) => skill.name));
  }, [userDetails]);

  useEffect(() => {
    if (projectData?.categories) {
      let allSkills = [];
      projectData.categories.forEach((ele) => {
        if (ele?.skills?.length) allSkills = [...allSkills, ...ele.skills];
      });
      setSelectedIds(
        allSkills
          .filter((ele) => selectedSkills.includes(ele.name))
          .map((ele) => ele._id)
      );
    }
  }, [selectedSkills, projectData]);

  const handleChipClick = (skill) => {
    if (selectedSkills.includes(skill)) {
      setSelectedSkills(selectedSkills.filter((item) => item !== skill));
    } else {
      setSelectedSkills([...selectedSkills, skill]);
    }
    setSkillError(""); // Reset skill validation error
  };

  const updateSkill = async () => {
    LoaderShow();
    if (selectedIds.length === 0) {
      LoaderHide();
      toast.error(t("PleaseAddSkills"));
    } else {
      LoaderShow();

      const token = GetUserDetails().token;

      const dataToSend = {
        skills: selectedIds,
        category: selectedCategories,
      };
      // dataToSend.append("skills", selectedIds);
      // selectedCategories.forEach((name, index) => {
      //     dataToSend.append(`category[${index}]`, name);
      //   });

      try {
        const response = await updateAPI(
          `user/update/${userDetails._id}`,
          dataToSend,
          token
        );
        if (response.statusCode === 200) {
          LoaderHide();
          toast.success(t(response.message));
          setValue(0);
        }
      } catch (error) {
        LoaderHide();
        toast.error(error.message);
      }
    }
    LoaderHide();
  };

  const getUserDetails = async () => {
    try {
      LoaderShow();
      const token = GetUserDetails().token;
      const response = await getAPI("user/getdetails", token);
      if (response.statusCode === 200) {
        setUserDetails(response.data);
        setSelectedCategories(response.data.category.map((ele) => ele._id));
        LoaderHide();
      }
    } catch (error) {
      LoaderHide();
      toast.error(error.message);
    }
  };

  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const handleFileChanges = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile?.size > 1_048_576) {
      toast.warn(t("toast16"));
      return;
    }
    setFile(selectedFile);

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevUserDetails) => ({
      ...prevUserDetails,
      [name]: value,
    }));
  };

  const handleAboutMe = (event) => {
    const value = event.target.value;
    setUserDetails((prevFormData) => ({
      ...prevFormData,
      aboutMe: value,
    }));
  };

  const handleState = (event) => {
    const value = event.target.value;
    setUserDetails((prevFormData) => ({
      ...prevFormData,
      state: value,
    }));
  };

  const handlePerHourAmount = (event) => {
    const value = event.target.value;
    setUserDetails((prevFormData) => ({
      ...prevFormData,
      perHourAmount: value,
    }));
  };

  const handleCryptoAddress = (event) => {
    const value = event.target.value;
    setUserDetails((prevFormData) => ({
      ...prevFormData,
      cryptoAddress: value,
    }));
  };

  const handleChangegender = (event) => {
    const value = event.target.value;
    setUserDetails({ ...userDetails, gender: value });
  };

  const handleRateType = (event) => {
    const value = event.target.value;
    setUserDetails({ ...userDetails, rateType: value });
  };

  const handleChangeCountrys = (event) => {
    const value = event.target.value;
    setUserDetails({ ...userDetails, location: value });
  };

  const validateForm = () => {
    const errors = {};

    if (!userDetails.firstName.trim()) {
      errors.firstName = t("firstNameReq");
    } else if (!isValidName(userDetails.firstName)) {
      errors.firstName = t("firstNameGreater3");
    }

    if (!userDetails?.lastName?.trim()) {
      errors.lastName = t("lastNameReq");
    } else if (!isValidName(userDetails.lastName)) {
      errors.lastName = t("lastNameGreater3");
    }

    if (!userDetails?.cryptoAddress?.trim()) {
      errors.cryptoAddress = t("crytpoReq");
    } else if (!isValidUSDTAddress(userDetails.cryptoAddress)) {
      errors.cryptoAddress = t("validCrypto");
    }

    if (!userDetails?.perHourAmount || userDetails?.perHourAmount < 0) {
      errors.perHourAmount = t("hourRateReq");
    }
    // if (!userDetails?.state?.trim()) {
    //   errors.state = 'State name is required';
    // } else if (!isValidName(userDetails.state)) {
    //   errors.state = 'State name should be more than 3 characters';
    // }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const updateUser = async () => {
    if (!validateForm()) {
      return;
    }

    LoaderShow();

    const token = GetUserDetails().token;

    const dataToSend = new FormData();
    dataToSend.append("firstName", userDetails.firstName);
    dataToSend.append("lastName", userDetails.lastName);
    dataToSend.append("userName", userDetails.userName);
    dataToSend.append("email", userDetails.email);
    // dataToSend.append("role", userDetails.role._id);
    dataToSend.append("profile", file);
    dataToSend.append("gender", userDetails.gender);
    dataToSend.append("rateType", userDetails?.rateType);
    dataToSend.append("location", userDetails?.location);
    dataToSend.append("state", userDetails?.state);
    dataToSend.append("aboutMe", userDetails?.aboutMe);
    dataToSend.append("perHourAmount", userDetails?.perHourAmount);
    dataToSend.append("cryptoAddress", userDetails?.cryptoAddress.trim());
    dataToSend.append("languageLevel", "demo");
    // dataToSend.append('rateType', "Hourly Rate");
    // dataToSend.append('location', "India");
    // dataToSend.append('state', "Maharashtra");

    try {
      const response = await updateAPI(
        `user/update/${userDetails._id}`,
        dataToSend,
        token
      );
      if (response.statusCode === 200) {
        if (userDetails?.firebaseUID)
          await setDoc(doc(db, "users", userDetails.firebaseUID), {
            firstName: response?.data?.firstName || "",
            lastName: response?.data?.lastName || "",
            imageUrl: response?.data?.profile || "",
            createdAt: Timestamp.now(),
            lastSeen: Timestamp.now(),
            metadata: null,
            role: null,
            updatedAt: Timestamp.now(),
          });
        LoaderHide();
        toast.success(t(response.message));
        window.location.reload(true);
      }
    } catch (error) {
      LoaderHide();
      toast.error(error.message);
    }
  };

  const [deletePassword, setDeletePassword] = useState("");
  const [errorsDeletePassword, setErrorsDeletePassword] = useState({});

  const validatePassword = () => {
    const errors = {};

    if (!deletePassword.trim()) {
      errors.deletePassword = t("PasswordIsRequired");
    } else if (
      deletePassword.length < 8 ||
      !hasSpecialCharacter(deletePassword)
    ) {
      errors.deletePassword = t("PassWordMustBe8Characters");
    }

    return errors;
  };

  const deleteAccount = async (e) => {
    LoaderShow();
    e.preventDefault();
    const validationErrors = validatePassword();
    setErrorsDeletePassword(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      try {
        LoaderShow();

        const token = GetUserDetails().token;
        const dataToSend = {
          password: deletePassword,
          email: userDetails.email,
        };

        const response = await deleteAPI(
          "user/employee/account",
          dataToSend,
          token
        );
        if (response.statusCode === 200) {
          await deleteDoc(doc(db, "users", userDetails.firebaseUID));
          const roomRef = collection(db, "rooms");
          const roomQuery = query(
            roomRef,
            where("userIds", "array-contains", userDetails?.firebaseUID)
          );
          const rooms = await getDocs(roomQuery);
          rooms.forEach(async (documentSnapshot) => {
            await deleteDoc(doc(db, "rooms", documentSnapshot.id));
          });
          const supportRef = collection(db, "supportChat");
          const supportQuery = query(
            supportRef,
            where("userId", "==", userDetails?.firebaseUID)
          );
          const supports = await getDocs(supportQuery);
          supports.forEach(async (documentSnapshot) => {
            await deleteDoc(doc(db, "supportChat", documentSnapshot.id));
          });

          LoaderHide();
          navigate("/home");
          localStorage.removeItem("loginData");
          localStorage.removeItem("notif-token");
        } else {
          toast.error(t(response?.message));
          LoaderHide();
        }
      } catch (error) {
        LoaderHide();
        toast.error(error?.message);
      }
    }
    LoaderHide();
  };

  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [step, setStep] = useState("sendOTP"); // 'sendOTP' or 'verifyOTP'
  const [errorMessage, setErrorMessage] = useState("");
  const [otpError, setOtpError] = useState("");

  const handleSendOTP = async () => {
    const newErrors = {};

    if (!email.trim()) {
      newErrors.email = t("EmailIsRequired");
    } else if (!isValidEmail(email)) {
      newErrors.email = t("InvalidEmailFormat");
    }

    setErrorMessage(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        LoaderShow();
        const response = await postAPI(`auth/send-email-otp`, { email });
        if (response.statusCode === 200) {
          LoaderHide();
          setStep("verifyOTP");
          toast.success(t(response?.message));
        } else {
          LoaderHide();
          setErrorMessage("Failed to send OTP.");
          toast.success(t(response?.message));
        }
      } catch (error) {
        LoaderHide();
        setErrorMessage("Error sending OTP. Please try again.");
        toast.error(error?.message);
      }
    }
  };

  const handleVerifyOTP = async () => {
    if (!otp) {
      setOtpError(t("PleaseEnterOpt"));
    } else if (otp.length !== 6) {
      setOtpError(t("OTP6Digit"));
    } else {
      try {
        LoaderShow();
        const response = await postAPI(`auth/verify-email-otp`, {
          email,
          emailOTP: otp,
        });
        if (response.statusCode === 200) {
          toast.success(t(response?.message));
          LoaderHide();
          setValue(0);
          window.location.reload(true);
        } else {
          LoaderHide();
          setErrorMessage(t("toast13"));
          toast.error(t(response?.message));
        }
      } catch (error) {
        LoaderHide();
        if (error.message.includes("invalid")) {
          toast.error(t("toast13"));
          return;
        }
        if (error.message.includes("code-expired")) {
          toast.error(t("toast14"));
          return;
        }
        toast.error(error.message);
      }
    }
  };

  return (
    <>
      <section className="myProfile">
        <BalanceWithdrawDialog
          isEmployee={true}
          open={withdrawOpen}
          onClose={() => setWithdrawOpen(false)}
          data={data}
          balance={userDetails?.wallet || 0}
          cryptoAddress={userDetails?.cryptoAddress}
        />

        <div id="recaptcha-container"></div>
        <div id="hideloding" className="loding-display">
          <img src={loader} alt="loader-img" />
        </div>
        <Box sx={{ mb: 4 }}>
          <Typography
            className="employeeHead1"
            variant="h4"
            sx={{ pb: 1, fontWeight: 600 }}
          >
            {t("MyProfile")}
          </Typography>
          <Typography
            variant="p"
            sx={{ fontSize: 18, color: "#777" }}
          ></Typography>
        </Box>
        <Box
          className="plZero prZero"
          sx={{
            backgroundColor: "#fff",
            px: 4,

            my: 4,
          }}
        >
          <StyledTabs
            variant={isMobile ? "scrollable" : "standard"}
            scrollButtons={isMobile ? "auto" : "off"}
            allowScrollButtonsMobile={isMobile}
            sx={{ display: "flex" }}
            value={value}
            onChange={handleChange}
            // variant="scrollable"
            // scrollButtons="auto"
          >
            <StyledTab icon={<AccountCircleIcon />} label={t("Profile")} />
            <StyledTab icon={<CodeIcon />} label={t("Skills")} />
            {/* <StyledTab icon={<VpnKeyIcon />} label={t("ChangePassword")} /> */}
            <StyledTab
              icon={<DeleteIcon />}
              label={t("DeleteAccountEmployee")}
            />
            <StyledTab icon={<EmailIcon />} label={t("VerifyEmail")} />
            <StyledTab
              icon={<AccountBalanceWalletRoundedIcon />}
              label={t("wallet")}
            />
          </StyledTabs>
          <Box sx={{ py: 4, px: 2 }}>
            {/* Content for each tab can be added here */}
            {value === 0 && (
              <Box>
                <Typography
                  variant="h6"
                  sx={{ color: "#000", fontWeight: 600, pb: 2, px: 0 }}
                >
                  {t("Profile")}
                </Typography>
                <Box sx={{ py: 4, borderTop: 1, borderColor: "#E9E9E9" }}>
                  <Box
                    className="flexColumnRespo"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {/* <Avatar
                        alt="Profile Picture"
                        src={avatar}
                        sx={{
                          width: 120,
                          height: 120,
                          border: 1,
                          borderColor: "#ccc",
                        }}
                        onClick={() =>
                          document.getElementById("avatar-upload").click()
                        }
                      /> */}
                      {previewUrl ? (
                        <Avatar
                          src={previewUrl}
                          sx={{
                            width: 120,
                            height: 120,
                            border: 1,
                            borderColor: "#ccc",
                          }}
                        />
                      ) : (
                        <Avatar
                          src={userDetails?.profileImageUrl}
                          sx={{
                            width: 120,
                            height: 120,
                            border: 1,
                            borderColor: "#ccc",
                          }}
                        />
                      )}
                      {avatar && (
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: 0,
                            fontSize: 20,
                            borderRadius: 2,
                            padding: 0.4,
                            marginLeft: 10,
                          }}
                        >
                          <EditIcon
                            color="secondary"
                            sx={{
                              backgroundColor: "#0B3013",
                              fontSize: 20,
                              padding: 0.4,
                              borderRadius: 2,
                              color: "#fff",
                            }}
                            onClick={() =>
                              document.getElementById("avatar-upload").click()
                            }
                          />
                        </Box>
                      )}
                      {!avatar && (
                        <label
                          htmlFor="avatar-upload"
                          style={{
                            position: "absolute",
                            bottom: 0,
                            left: 86,
                            right: 0,
                            cursor: "pointer",
                          }}
                        >
                          <EditIcon
                            color="secondary"
                            sx={{
                              backgroundColor: "#0B3013",
                              fontSize: 20,
                              padding: 0.4,
                              borderRadius: 2,
                              color: "#fff",
                            }}
                          />
                        </label>
                      )}
                      <input
                        accept="image/*"
                        id="avatar-upload"
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleFileChanges}
                      />
                    </Box>
                    <Box className="filterSec">
                      {/* <Typography variant="p" sx={{ px: 3, display: "block" }}>
                        Max file size is 1MB, Minimum dimension: 330x300 And
                        Suitable files are <br />
                        .jpg & .png
                      </Typography> */}
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Box sx={{ my: 1 }}>
                        <FormLabel
                          sx={{
                            display: "block",
                            textAlign: "start",
                            py: 1.4,
                            fontSize: 14,
                          }}
                        >
                          {t("First")}
                        </FormLabel>
                        <TextField
                          variant="outlined"
                          sx={{
                            background: isFocused ? "#FFFFFF" : "transparent",
                            borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                            boxShadow: isFocused
                              ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
                              : "none",
                          }}
                          fullWidth={true}
                          placeholder={t("First")}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          id="firstName"
                          name="firstName"
                          onChange={handleInputChange}
                          value={userDetails?.firstName}
                          helperText={validationErrors.firstName}
                          error={!!validationErrors.firstName}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box sx={{ my: 1 }}>
                        <FormLabel
                          sx={{
                            display: "block",
                            textAlign: "start",
                            py: 1.4,
                            fontSize: 14,
                          }}
                        >
                          {t("Last")}
                        </FormLabel>
                        <TextField
                          variant="outlined"
                          sx={{
                            background: isFocused ? "#FFFFFF" : "transparent",
                            borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                            boxShadow: isFocused
                              ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
                              : "none",
                          }}
                          fullWidth={true}
                          placeholder={t("Last")}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          id="lastName"
                          name="lastName"
                          onChange={handleInputChange}
                          value={userDetails?.lastName}
                          helperText={validationErrors.lastName}
                          error={!!validationErrors.lastName}
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Box sx={{ my: 1 }}>
                        <FormLabel
                          sx={{
                            display: "block",
                            textAlign: "start",
                            py: 1.4,
                            fontSize: 14,
                          }}
                        >
                          {t("EmailAdd")}
                        </FormLabel>
                        <TextField
                          disabled
                          variant="outlined"
                          sx={{
                            background: isFocused ? "#FFFFFF" : "transparent",
                            borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                            boxShadow: isFocused
                              ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
                              : "none",
                          }}
                          fullWidth={true}
                          placeholder={t("EmailAdd")}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          id="email"
                          name="email"
                          onChange={handleInputChange}
                          value={userDetails?.email}
                          helperText={validationErrors.email}
                          error={!!validationErrors.email}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Box sx={{ my: 1 }}>
                        <FormLabel
                          sx={{
                            display: "block",
                            textAlign: "start",
                            py: 1.4,
                            fontSize: 14,
                          }}
                        >
                          {t("PhoneNo")}
                        </FormLabel>
                        <TextField
                          disabled
                          fullWidth={true}
                          country={"us"}
                          // value={phoneNumber}
                          onChange={handlePhoneNumberChange}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          inputComponent={renderInput}
                          id="phone"
                          name="phone"
                          value={userDetails?.phoneNumber}
                          helperText={validationErrors.phoneNumber}
                          error={!!validationErrors.phoneNumber}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      {/* <Box>
                        <FormLabel
                          sx={{
                            display: "block",
                            textAlign: "start",
                            py: 1.4,
                            fontSize: 14,
                          }}
                        >
                          Hourly Rate
                        </FormLabel>
                        <Dropdown
                          value={hourly}
                          onChange={(e) => setDepartment(e.target.value)}
                          options={hourlyOptions}
                          label="Department"
                        />
                      </Box> */}
                      <FormControl
                        sx={{
                          mt: 2,
                        }}
                        fullWidth
                      >
                        <InputLabel id="gender-select-label">
                          {t("Hourly")}
                        </InputLabel>
                        <Select
                          labelId="gender-select-label"
                          id="gender-select"
                          value={userDetails?.rateType}
                          label="Gender"
                          onChange={handleRateType}
                        >
                          {hourlyOptions?.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl
                        sx={{
                          mt: 2,
                        }}
                        fullWidth
                      >
                        <InputLabel id="gender-select-label">
                          {t("Gender")}
                        </InputLabel>
                        <Select
                          labelId="gender-select-label"
                          id="gender-select"
                          value={userDetails?.gender}
                          label="Gender"
                          onChange={handleChangegender}
                        >
                          {genderOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {t(option.label)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {/* <Box>
                        <FormLabel
                          sx={{
                            display: "block",
                            textAlign: "start",
                            py: 1.4,
                            fontSize: 14,
                          }}
                        >
                          Gender
                        </FormLabel>
                        <Dropdown
                          value={gender}
                          onChange={handleChangegender}
                          options={genderOptions?.map(value => ({
                            value,
                            label: `${value?.label}`
                          }))}
                          label="Department"
                        />
                      </Box> */}
                    </Grid>

                    {/* <Grid item xs={12} md={6}>
                      <Box>
                        <FormLabel
                          sx={{
                            display: "block",
                            textAlign: "start",
                            py: 1.4,
                            fontSize: 14,
                          }}
                        >
                          Specialization
                        </FormLabel>
                        <Dropdown
                          value={specialization}
                          onChange={(e) => setDepartment(e.target.value)}
                          options={specializationOptions}
                          label="Department"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box>
                        <FormLabel
                          sx={{
                            display: "block",
                            textAlign: "start",
                            py: 1.4,
                            fontSize: 14,
                          }}
                        >
                          Type
                        </FormLabel>
                        <Dropdown
                          value={type}
                          onChange={(e) => setDepartment(e.target.value)}
                          options={typeOptions}
                          label="Department"
                        />
                      </Box>
                    </Grid> */}

                    <Grid item xs={12} md={6}>
                      {/* <Box>
                        <FormLabel
                          sx={{
                            display: "block",
                            textAlign: "start",
                            py: 1.4,
                            fontSize: 14,
                          }}
                        >
                          Country
                        </FormLabel>
                        <Dropdown
                          value={country}
                          onChange={(e) => setDepartment(e.target.value)}
                          options={countryOptions}
                          label="Department"
                        />
                      </Box> */}
                      <FormControl
                        sx={{
                          mt: 3,
                        }}
                        fullWidth
                      >
                        <InputLabel id="gender-select-label">
                          {t("Country")}
                        </InputLabel>
                        <Select
                          disabled
                          labelId="gender-select-label"
                          id="gender-select"
                          value={userDetails?.location}
                          label={t("Country")}
                          onChange={handleChangeCountrys}
                        >
                          {countries?.map((option) => (
                            <MenuItem key={option.label} value={option.label}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      {/* <Box>
                        <FormLabel
                          sx={{
                            display: "block",
                            textAlign: "start",
                            py: 1.4,
                            fontSize: 14,
                          }}
                        >
                          City
                        </FormLabel>
                        <Dropdown
                          value={city}
                          onChange={(e) => setDepartment(e.target.value)}
                          options={cityOptions}
                          label="Department"
                        />
                      </Box> */}
                      <FormControl
                        sx={{
                          mt: 3,
                        }}
                        fullWidth
                      >
                        <FormLabel
                          sx={{
                            display: "block",
                            textAlign: "start",
                            // py: 1.4,
                            mt: -2.3,
                            fontSize: 14,
                          }}
                        >
                          {t("State")}
                        </FormLabel>
                        <TextField
                          variant="outlined"
                          sx={{
                            background: isFocused ? "#FFFFFF" : "transparent",
                            borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                            boxShadow: isFocused
                              ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
                              : "none",
                          }}
                          fullWidth={true}
                          placeholder={t("State")}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          id="sate"
                          name="sate"
                          onChange={handleState}
                          value={userDetails?.state}
                          helperText={validationErrors.state}
                          error={!!validationErrors.state}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl
                        sx={{
                          mt: 3,
                        }}
                        fullWidth
                      >
                        <FormLabel
                          sx={{
                            display: "block",
                            textAlign: "start",
                            // py: 1.4,
                            mt: -2.3,
                            fontSize: 14,
                          }}
                        >
                          {t("perhrRate")}
                        </FormLabel>
                        <TextField
                          type="number"
                          variant="outlined"
                          sx={{
                            background: isFocused ? "#FFFFFF" : "transparent",
                            borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                            boxShadow: isFocused
                              ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
                              : "none",
                          }}
                          fullWidth={true}
                          placeholder={t("perhrRate")}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          id="perHourAmount"
                          name="perHourAmount"
                          onChange={handlePerHourAmount}
                          value={userDetails?.perHourAmount}
                          helperText={validationErrors.perHourAmount}
                          error={!!validationErrors.perHourAmount}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl
                        sx={{
                          mt: 3,
                        }}
                        fullWidth
                      >
                        <FormLabel
                          sx={{
                            display: "block",
                            textAlign: "start",
                            // py: 1.4,
                            mt: -2.3,
                            fontSize: 14,
                          }}
                        >
                          {t("cryptoAdd")}
                        </FormLabel>
                        <TextField
                          variant="outlined"
                          sx={{
                            background: isFocused ? "#FFFFFF" : "transparent",
                            borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                            boxShadow: isFocused
                              ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
                              : "none",
                          }}
                          fullWidth={true}
                          placeholder={t("cryptoAdd")}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          id="cryptoAddress"
                          name="cryptoAddress"
                          onChange={handleCryptoAddress}
                          value={userDetails?.cryptoAddress}
                          helperText={validationErrors.cryptoAddress}
                          error={!!validationErrors.cryptoAddress}
                        />
                      </FormControl>
                    </Grid>

                    {/* <Grid item xs={12} md={6}>
                      <Box>
                        <FormLabel
                          sx={{
                            display: "block",
                            textAlign: "start",
                            py: 1.4,
                            fontSize: 14,
                          }}
                        >
                          Language
                        </FormLabel>
                        <Dropdown
                          value={language}
                          onChange={(e) => setDepartment(e.target.value)}
                          options={languageOptions}
                          label="Department"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box>
                        <FormLabel
                          sx={{
                            display: "block",
                            textAlign: "start",
                            py: 1.4,
                            fontSize: 14,
                          }}
                        >
                          Languages Level
                        </FormLabel>
                        <Dropdown
                          value={languageLevel}
                          onChange={(e) => setDepartment(e.target.value)}
                          options={languageLevelOptions}
                          label="Department"
                        />
                      </Box>
                    </Grid> */}
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box sx={{ my: 3 }}>
                        <FormLabel
                          sx={{
                            display: "block",
                            textAlign: "start",
                            py: 1,
                            fontSize: 14,
                          }}
                        >
                          {t("Introduce")}
                        </FormLabel>
                        <Textarea
                          sx={{
                            width: "100%",
                            border: 1,
                            borderColor: "#cbcbcb",
                            borderRadius: 1,
                          }}
                          id="aboutme"
                          name="aboutme"
                          onChange={handleAboutMe}
                          value={userDetails?.aboutMe}
                          helperText={validationErrors.aboutMe}
                          error={!!validationErrors.aboutMe}
                          minRows={6}
                          maxRows={6}
                          placeholder={t("Iama")}
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <Box sx={{ my: 2, display: "flex", justifyContent: "start" }}>
                    <Button
                      onClick={updateUser}
                      className="SignUpBtn"
                      sx={{
                        textTransform: "capitalize",
                        py: "8px !important",
                        px: "24px !important",
                        width: "auto !important",
                        borderRadius: 12,
                        maxWidth: "auto !important",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                      }}
                      endIcon={
                        <Box
                          component="img"
                          src={arrowCross}
                          sx={{ width: 24, height: 24, marginRight: 1 }}
                        />
                      }
                    >
                      {t("Save")}
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
            {value === 1 && (
              // <Box>
              //   <Box>
              //     <Typography
              //       variant="h6"
              //       sx={{ color: "#000", fontWeight: 600, pb: 2, px: 0 }}
              //     >
              //       Skills
              //     </Typography>
              //     <Box
              //       sx={{ py: 1, borderTop: 1, borderColor: "#E9E9E9" }}
              //     ></Box>
              //     <Box>
              //       <Grid container spacing={2}>
              //         <Grid item xs={12} md={6}>
              //           <Box>
              //             <FormLabel
              //               sx={{
              //                 display: "block",
              //                 textAlign: "start",
              //                 py: 1.4,
              //                 fontSize: 14,
              //               }}
              //             >
              //               Skills 1
              //             </FormLabel>
              //             <Dropdown
              //               value={hourly}
              //               onChange={(e) => setDepartment(e.target.value)}
              //               options={hourlyOptions}
              //               label="Department"
              //             />
              //           </Box>
              //         </Grid>
              //         <Grid item xs={12} md={6}>
              //           <Box>
              //             <FormLabel
              //               sx={{
              //                 display: "block",
              //                 textAlign: "start",
              //                 py: 1.4,
              //                 fontSize: 14,
              //               }}
              //             >
              //               Point
              //             </FormLabel>
              //             <Dropdown
              //               value={gender}
              //               onChange={(e) => setDepartment(e.target.value)}
              //               options={genderOptions}
              //               label="Department"
              //             />
              //           </Box>
              //         </Grid>
              //         <Grid item xs={12} md={6}>
              //           <Box>
              //             <FormLabel
              //               sx={{
              //                 display: "block",
              //                 textAlign: "start",
              //                 py: 1.4,
              //                 fontSize: 14,
              //               }}
              //             >
              //               Skills 2
              //             </FormLabel>
              //             <Dropdown
              //               value={specialization}
              //               onChange={(e) => setDepartment(e.target.value)}
              //               options={specializationOptions}
              //               label="Department"
              //             />
              //           </Box>
              //         </Grid>
              //         <Grid item xs={12} md={6}>
              //           <Box>
              //             <FormLabel
              //               sx={{
              //                 display: "block",
              //                 textAlign: "start",
              //                 py: 1.4,
              //                 fontSize: 14,
              //               }}
              //             >
              //               Point
              //             </FormLabel>
              //             <Dropdown
              //               value={type}
              //               onChange={(e) => setDepartment(e.target.value)}
              //               options={typeOptions}
              //               label="Department"
              //             />
              //           </Box>
              //         </Grid>
              //         <Grid item xs={12} md={6}>
              //           <Box>
              //             <FormLabel
              //               sx={{
              //                 display: "block",
              //                 textAlign: "start",
              //                 py: 1.4,
              //                 fontSize: 14,
              //               }}
              //             >
              //               Skills 3
              //             </FormLabel>
              //             <Dropdown
              //               value={country}
              //               onChange={(e) => setDepartment(e.target.value)}
              //               options={countryOptions}
              //               label="Department"
              //             />
              //           </Box>
              //         </Grid>
              //         <Grid item xs={12} md={6}>
              //           <Box sx={{ pb: 2 }}>
              //             <FormLabel
              //               sx={{
              //                 display: "block",
              //                 textAlign: "start",
              //                 py: 1.4,
              //                 fontSize: 14,
              //               }}
              //             >
              //               Point
              //             </FormLabel>
              //             <Dropdown
              //               value={city}
              //               onChange={(e) => setDepartment(e.target.value)}
              //               options={cityOptions}
              //               label="Department"
              //             />
              //           </Box>
              //         </Grid>
              //       </Grid>

              //       <Box
              //         sx={{ my: 2, display: "flex", justifyContent: "start" }}
              //       >
              //         <Button
              //           className="SignUpBtn"
              //           sx={{
              //             textTransform: "capitalize",
              //             py: "8px !important",
              //             px: "24px !important",
              //             width: "auto !important",
              //             borderRadius: 12,
              //             maxWidth: "auto !important",
              //             color: "white",
              //             display: "flex",
              //             alignItems: "center",
              //           }}
              //           endIcon={
              //             <Box
              //               component="img"
              //               src={arrowCross}
              //               sx={{ width: 24, height: 24, marginRight: 1 }}
              //             />
              //           }
              //         >
              //           Save
              //         </Button>
              //       </Box>
              //     </Box>
              //   </Box>
              // </Box>
              <>
                <Box
                  sx={{
                    mb: 3,
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      textAlign: "start",
                      fontWeight: 700,
                      pb: 1,
                      textTransform: "capitalize",
                    }}
                  >
                    {t("Category")}
                  </Typography>
                  <Select
                    sx={{
                      width: "100%",
                    }}
                    MenuProps={{ disableScrollLock: true }}
                    multiple
                    value={selectedCategories}
                    onChange={(ev) =>
                      setSelectedCategories(
                        ev.target.value.filter((ele) => ele)
                      )
                    }
                    IconComponent={CustomCaretIcon} // Assuming CustomCaretIcon is defined elsewhere
                    input={<OutlinedInput label="Skills" />}
                    renderValue={(selected) => (
                      <Stack gap={1} direction="row" flexWrap="wrap">
                        {selected.map((value) => {
                          const selectedSkill = projectData?.categories?.find(
                            (skill) => skill._id === value
                          );
                          return (
                            selectedSkill && (
                              <Chip
                                key={value}
                                label={
                                  selectedSkill?.[
                                    i18n.language != "en"
                                      ? `name_${i18n.language}`
                                      : "name"
                                  ]
                                }
                              />
                            )
                          );
                        })}
                      </Stack>
                    )}
                    onClose={() => setIsCategoryMenuOpen(false)} // Close menu on select
                    onOpen={() => setIsCategoryMenuOpen(true)} // Open menu on select
                    open={isCategoryMenuOpen} // Controlled open state
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        borderColor: "",
                        mx: 1,
                      }}
                    >
                      <IconButton
                        size="small"
                        onClick={() => setIsCategoryMenuOpen(false)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                    {projectData?.categories?.map((skill) => (
                      <MenuItem key={skill._id} value={skill._id}>
                        {
                          skill?.[
                            i18n.language != "en"
                              ? `name_${i18n.language}`
                              : "name"
                          ]
                        }
                      </MenuItem>
                    ))}
                  </Select>
                  {categoryError && (
                    <Typography variant="p" sx={{ color: "red" }}>
                      {categoryError}
                    </Typography>
                  )}
                </Box>
                <Box>
                  <Typography
                    variant="h5"
                    sx={{ textAlign: "start", fontWeight: 700 }}
                  >
                    {t("WhatAreTheMainSkills")}
                  </Typography>
                  <p className="beginText">{t("ThiHelp")}</p>
                </Box>

                <Box
                  sx={{
                    mt: 2,
                    border: 1,
                    p: 2,
                    borderColor: "#8692A6",
                    borderRadius: 2,
                    color: "#fff",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px 0",
                    justifyContent: "flex-start",
                  }}
                >
                  {selectedSkills?.map((skill, index) => (
                    <Chip
                      key={index}
                      label={skill}
                      onDelete={() => handleChipClick(skill)}
                      deleteIcon={
                        <RemoveIcon sx={{ color: "#fff !important" }} />
                      } // Remove the color prop here
                      sx={{
                        mr: 1,
                        bgcolor: "#0B3013",
                        color: "#fff !important",
                        "& .MuiSvgIcon-root": {
                          // Apply styles to the nested icon
                          color: "#fff", // Change this to the desired color
                        },
                      }}
                    />
                  ))}
                </Box>
                {skillError && (
                  <Typography variant="body2" sx={{ color: "red", mt: 1 }}>
                    {skillError}
                  </Typography>
                )}

                <Box sx={{ mt: 4 }}>
                  <Typography variant="h6" sx={{ textAlign: "start", pb: 2 }}>
                    {t("Suggested")}
                  </Typography>
                  {suggestedSkills?.map((skill, index) => (
                    <Chip
                      key={index}
                      label={skill}
                      clickable
                      onClick={() => handleChipClick(skill)}
                      deleteIcon={
                        selectedSkills?.includes(skill) ? (
                          <RemoveIcon color="secondary" />
                        ) : (
                          <AddIcon color="secondary" />
                        )
                      }
                      color={
                        selectedSkills?.includes(skill) ? "default" : "default"
                      }
                      onDelete={() => handleChipClick(skill)}
                      sx={{
                        mr: 1,
                        mb: 1,
                        ...(selectedSkills?.includes(skill) && {
                          bgcolor: "#fff",
                          border: 1,
                          borderColor: "#0B3013",
                          color: "#0B3013",
                        }), // Apply background color for selected skills
                      }}
                    />
                  ))}
                </Box>
                <Box sx={{ my: 2, display: "flex", justifyContent: "start" }}>
                  <Button
                    onClick={updateSkill}
                    className="SignUpBtn"
                    sx={{
                      textTransform: "capitalize",
                      py: "8px !important",
                      px: "24px !important",
                      width: "auto !important",
                      borderRadius: 12,
                      maxWidth: "auto !important",
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                    }}
                    endIcon={
                      <Box
                        component="img"
                        src={arrowCross}
                        sx={{ width: 24, height: 24, marginRight: 1 }}
                      />
                    }
                  >
                    {t("UpdateSkill")}
                  </Button>
                </Box>
              </>
            )}
            {/* {value === 2 && (
              <Box>
                <Box>
                  <Typography
                    variant="h6"
                    sx={{ color: "#000", fontWeight: 600, pb: 2, px: 0 }}
                  >
                    {t("ChangePassword")}
                  </Typography>
                  <Box sx={{ borderTop: 1, borderColor: "#E9E9E9" }}></Box>
                  <Box>
                    <Grid container spacing={2} sx={{ my: 1 }}>
                      <Grid item xs={12} md={6}>
                        <Box>
                          <FormLabel
                            sx={{
                              display: "block",
                              textAlign: "start",
                              py: 1,
                              fontSize: 14,
                            }}
                          >
                            {t("OldPassword")}
                          </FormLabel>
                          <TextField
                            variant="outlined"
                            sx={{
                              background: isFocused ? "#FFFFFF" : "transparent",
                              borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                              boxShadow: isFocused
                                ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
                                : "none",
                            }}
                            fullWidth={true}
                            placeholder={t("EnterOldPassword")}
                            type={showOldPassword ? "text" : "password"} // Change the type dynamically
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            error={Boolean(errors.oldPassword)}
                            helperText={errors.oldPassword}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={handleToggleOldPasswordVisibility}
                                    edge="end"
                                  >
                                    {showOldPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ my: 1 }}>
                      <Grid item xs={12} md={6}>
                        <Box>
                          <FormLabel
                            sx={{
                              display: "block",
                              textAlign: "start",
                              py: 1,
                              fontSize: 14,
                            }}
                          >
                            {t("NewPassword")}
                          </FormLabel>
                          <TextField
                            variant="outlined"
                            sx={{
                              background: isFocused ? "#FFFFFF" : "transparent",
                              borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                              boxShadow: isFocused
                                ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
                                : "none",
                            }}
                            fullWidth={true}
                            placeholder={t("EnterNewPassword")}
                            type={showNewPassword ? "text" : "password"} // Change the type dynamically
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            error={Boolean(errors.newPassword)}
                            helperText={errors.newPassword}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={handleToggleNewPasswordVisibility}
                                    edge="end"
                                  >
                                    {showNewPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ my: 1 }}>
                      <Grid item xs={12} md={6}>
                        <Box>
                          <FormLabel
                            sx={{
                              display: "block",
                              textAlign: "start",
                              py: 1,
                              fontSize: 14,
                            }}
                          >
                            {t("ConfirmPassword")}
                          </FormLabel>
                          <TextField
                            variant="outlined"
                            sx={{
                              background: isFocused ? "#FFFFFF" : "transparent",
                              borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                              boxShadow: isFocused
                                ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
                                : "none",
                            }}
                            fullWidth={true}
                            placeholder={t("EnterConfirmPassword")}
                            type={showConfirmPassword ? "text" : "password"} // Change the type dynamically
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            error={Boolean(errors.confirmPassword)}
                            helperText={errors.confirmPassword}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={
                                      handleToggleConfirmPasswordVisibility
                                    }
                                    edge="end"
                                  >
                                    {showConfirmPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>

                    <Box
                      sx={{ mt: 5, display: "flex", justifyContent: "start" }}
                    >
                      <Button
                        onClick={handleSubmitChangePassword}
                        className="SignUpBtn"
                        sx={{
                          textTransform: "capitalize",
                          py: "8px !important",
                          px: "24px !important",
                          width: "auto !important",
                          borderRadius: 12,
                          maxWidth: "auto !important",
                          color: "white",
                          display: "flex",
                          alignItems: "center",
                        }}
                        endIcon={
                          <Box
                            component="img"
                            src={arrowCross}
                            sx={{ width: 24, height: 24, marginRight: 1 }}
                          />
                        }
                      >
                        Save
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )} */}
            {value === 2 && (
              // <>
              //   <Box>
              //     <Box>
              //       <Typography
              //         variant="h6"
              //         sx={{ color: "#000", fontWeight: 600, pb: 2, px: 0 }}
              //       >
              //         {t("DeleteAccountEmployee")}{" "}
              //       </Typography>
              //       <Box sx={{ borderTop: 1, borderColor: "#E9E9E9" }}></Box>
              //       <Box>
              //         <Grid container spacing={2} sx={{ my: 1 }}>
              //           <Grid item xs={12} md={6}>
              //             <Box>
              //               <Typography
              //                 variant="h7"
              //                 sx={{ fontWeight: "bold" }}
              //               >
              //                 {t("CloseAccount")}
              //               </Typography>
              //               <br />
              //               <Typography
              //                 variant="p"
              //                 sx={{ color: "#777", py: 2, display: "block" }}
              //               >
              //                 {t("WarningCloseAccount")}
              //               </Typography>
              //             </Box>
              //             <Box>
              //               <FormLabel
              //                 sx={{
              //                   display: "block",
              //                   textAlign: "start",
              //                   py: 1,
              //                   fontSize: 14,
              //                 }}
              //               >
              //                 {t("EnterPass")}
              //               </FormLabel>
              //               <TextField
              //                 variant="outlined"
              //                 sx={{
              //                   background: isFocused
              //                     ? "#FFFFFF"
              //                     : "transparent",
              //                   borderColor: isFocused ? "#0B3013" : "#E0E0E0",
              //                   boxShadow: isFocused
              //                     ? "0px 4px 10px 3px rgba(0,0,0,0.11)"
              //                     : "none",
              //                 }}
              //                 fullWidth={true}
              //                 placeholder={t("EnterPass")}
              //                 type={showPassword ? "text" : "password"} // Change the type dynamically
              //                 onFocus={handleFocus}
              //                 onBlur={handleBlur}
              //                 value={deletePassword}
              //                 onChange={(e) =>
              //                   setDeletePassword(e.target.value)
              //                 }
              //                 error={Boolean(
              //                   errorsDeletePassword.deletePassword
              //                 )}
              //                 helperText={errorsDeletePassword.deletePassword}
              //                 InputProps={{
              //                   endAdornment: (
              //                     <InputAdornment position="end">
              //                       <IconButton
              //                         onClick={handleTogglePasswordVisibility}
              //                         edge="end"
              //                       >
              //                         {showPassword ? (
              //                           <Visibility />
              //                         ) : (
              //                           <VisibilityOff />
              //                         )}
              //                       </IconButton>
              //                     </InputAdornment>
              //                   ),
              //                 }}
              //               />
              //             </Box>
              //           </Grid>
              //         </Grid>

              //         <Box
              //           sx={{ mt: 5, display: "flex", justifyContent: "start" }}
              //         >
              //           <Button
              //             className="SignUpBtn"
              //             sx={{
              //               textTransform: "capitalize",
              //               py: "8px !important",
              //               px: "24px !important",
              //               width: "auto !important",
              //               borderRadius: 12,
              //               maxWidth: "auto !important",
              //               color: "white",
              //               display: "flex",
              //               alignItems: "center",
              //             }}
              //             onClick={deleteAccount}
              //             endIcon={
              //               <Box
              //                 component="img"
              //                 src={arrowCross}
              //                 sx={{ width: 24, height: 24, marginRight: 1 }}
              //               />
              //             }
              //           >
              //             {t("DeleteAccountEmployee")}
              //           </Button>
              //         </Box>
              //       </Box>
              //     </Box>
              //   </Box>

              //   <OTPModal
              //     isOpen={isModalOpen}
              //     onClose={handleCloseModal}
              //     onSubmit={handleOtpSubmit}
              //   />
              // </>
              <>
                {!isRegistering && (
                  <Box sx={{ marginTop: 0 }}>
                    <Typography
                      variant="h4"
                      sx={{
                        mb: 1,
                        pb: 1,
                        fontWeight: 600,
                        borderBottom: 1,
                        borderColor: "#E9E9E9",
                      }}
                    >
                      <div>{t("DeleteAccount")}</div>
                    </Typography>
                    <div>
                      <FormLabel
                        sx={{
                          display: "block",
                          textAlign: "start",
                          py: 1,
                          fontSize: 14,
                        }}
                      >
                        {t("PhoneNo")}
                      </FormLabel>
                      <PhoneInput
                        country={"us"}
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        inputComponent={renderInput}
                      />
                      {phoneNumberError && (
                        <Typography variant="body2" sx={{ color: "red" }}>
                          {phoneNumberError}
                        </Typography>
                      )}
                    </div>
                    <Button
                      onClick={handleSignInEmployer} // Toggle to display registration section
                      className="SignUpBtn"
                      sx={{
                        my: 4,
                        textTransform: "capitalize",
                        width: 100,
                        color: "white",
                      }}
                    >
                      {t("getOTP")}
                    </Button>
                  </Box>
                )}

                {isRegistering && ( // Conditionally render the registration section based on state
                  <Box sx={{ width: "100%", marginTop: 8 }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "100%",
                      }}
                    >
                      <Box>
                        <Typography
                          variant="h5"
                          sx={{ textAlign: "start", fontWeight: 700 }}
                        >
                          {t("OTP")}{" "}
                        </Typography>
                        <p className="beginText">{t("WeOTP")}</p>
                      </Box>

                      <Box sx={{ pt: 3, maxWidth: "500px" }}>
                        <OtpInput
                          inputStyle={{
                            border: "2px solid #0B3013",
                            borderRadius: "8px",
                            width: "54px",
                            height: "54px",
                            fontSize: "18px",
                            color: "#000",
                            fontWeight: "400",
                            caretColor: "blue",
                          }}
                          focusStyle={{
                            border: "1px solid #CFD3DB",
                            outline: "none",
                          }}
                          value={otp}
                          onChange={setOtp}
                          numInputs={6}
                          inputType="password"
                          renderSeparator={
                            <span
                              style={{ paddingLeft: 5, paddingRight: 5 }}
                            ></span>
                          }
                          renderInput={(props) => (
                            <input {...props} className="otpInput" />
                          )}
                          containerStyle="otpContainer" // Apply the custom CSS class
                        />
                        {otpError && (
                          <Typography
                            variant="body2"
                            sx={{ color: "red", mt: 1 }}
                          >
                            {otpError}
                          </Typography>
                        )}
                      </Box>

                      <Box
                        sx={{
                          pt: 5,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <span className="timerText">{`${
                          timer < 10 ? "0" : ""
                        }${timer} Sec`}</span>
                        <span
                          style={{
                            cursor: resendDisabled ? "disabled" : "pointer",
                          }}
                          className={`resend ${
                            resendDisabled ? "disabled" : ""
                          }`}
                          onClick={
                            !resendDisabled ? handleResendOtp : undefined
                          }
                        >
                          {t("resendOTP")}
                        </span>
                      </Box>

                      <Box
                        sx={{
                          mt: 4,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          onClick={handleVerifyNumberOTP}
                          className="SignUpBtn"
                          sx={{
                            textTransform: "capitalize",
                            width: 100,
                            color: "white",
                          }}
                        >
                          {t("Verify")}
                        </Button>
                      </Box>

                      <Box
                        sx={{
                          my: 1,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <FormLabel
                          sx={{
                            display: "flex",
                            textAlign: "center",
                            py: 1,
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: 14,
                          }}
                        >
                          <LockOutlinedIcon fontSize="12" sx={{ px: 1 }} />
                          {t("YourInfo")}
                        </FormLabel>
                      </Box>
                    </Box>
                  </Box>
                )}
              </>
            )}

            {value === 3 && (
              // <Box>
              //   <Box>
              //     <Typography variant="h4" sx={{ pb: 1, fontWeight: 600 }}>
              //       Verify Email
              //     </Typography>
              //     <Box>
              //       {/* <Box sx={{ borderTop: 1, borderColor: "#E9E9E9" }}></Box> */}
              //       <Grid container>
              //         <Grid
              //           sx={{ background: "#fff", borderRadius: 5, px: 4, py: 2, my: 3 }}
              //           xs={12}
              //         >
              //           <Box>
              //             <Box sx={{ borderBottom: 1, borderColor: "#E9E9E9", py: 1 }}>
              //               <Typography variant="h6" sx={{ fontWeight: 600 }}>
              //                 Verify Email
              //               </Typography>
              //             </Box>
              //             <Box sx={{ py: 2 }}>
              //               <FormLabel
              //                 sx={{
              //                   display: "block",
              //                   textAlign: "start",
              //                   py: 1,
              //                   fontSize: 14,
              //                 }}
              //               >
              //                 Email
              //               </FormLabel>
              //               <TextField
              //                 variant="outlined"
              //                 sx={{ width: 500, maxWidth: 400 }}
              //                 placeholder="Enter Email"
              //                 type="email" // Change the type dynamically

              //               // onChange={(e) => setOldPassword(e.target.value)}
              //               // error={oldPasswordError}
              //               // helperText={oldPasswordError}
              //               />
              //             </Box>
              //           </Box>
              //           <Box sx={{ mt: 3, display: "flex", justifyContent: "start" }}>
              //             <Button
              //               // onClick={handleSubmit}
              //               className="SignUpBtn"
              //               sx={{
              //                 textTransform: "capitalize",
              //                 py: "8px !important",
              //                 px: "24px !important",
              //                 width: "auto !important",
              //                 borderRadius: 12,
              //                 maxWidth: "auto !important",
              //                 color: "white",
              //                 display: "flex",
              //                 alignItems: "center",
              //               }}
              //               endIcon={
              //                 <Box
              //                   component="img"
              //                   src={arrowCross}
              //                   sx={{ width: 24, height: 24, marginRight: 1 }}
              //                 />
              //               }
              //             >
              //               Verify
              //             </Button>
              //           </Box>
              //         </Grid>
              //       </Grid>
              //     </Box>
              //   </Box>
              // </Box>
              <>
                {userDetails?.isEmailVerified ? (
                  <Box
                    sx={{
                      background: "#fff",
                      borderRadius: 5,
                      px: 4,
                      pb: 10,
                      pt: 5,
                      my: 3,
                    }}
                    xs={12}
                  >
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <img src={vecNoimag} alt="dhn" />
                    </Box>
                    <Typography
                      variant="h4"
                      sx={{ textAlign: "center", color: "#0B3013" }}
                    >
                      {t("EmailAlreadyVerified")}
                    </Typography>
                  </Box>
                ) : (
                  <Box>
                    <Box>
                      <Typography
                        variant="h4"
                        sx={{ mb: 1, pb: 1, fontWeight: 600 }}
                      >
                        <div>{t("VerifyEmail")}</div>
                      </Typography>
                      <Box>
                        <Grid container>
                          <Grid
                            sx={{
                              background: "#fff",
                              borderRadius: 5,
                            }}
                            xs={12}
                          >
                            <Box>
                              <Box
                                sx={{
                                  borderBottom: 1,
                                  borderColor: "#E9E9E9",
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  sx={{ fontWeight: 600 }}
                                >
                                  {/* {step === 'sendOTP' ? 'Send OTP' : 'Verify Email'} */}
                                </Typography>
                              </Box>
                              {step === "sendOTP" ? (
                                <Box sx={{ py: 2 }}>
                                  <FormLabel
                                    sx={{
                                      display: "block",
                                      textAlign: "start",
                                      py: 1,
                                      fontSize: 14,
                                    }}
                                  >
                                    {t("Email")}
                                  </FormLabel>
                                  <TextField
                                    variant="outlined"
                                    sx={{ width: 500, maxWidth: 400 }}
                                    placeholder={t("EnterEmailAdd")}
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    // error={!!errorMessage.email}
                                    // helperText={errorMessage.email}
                                  />
                                  <Button
                                    onClick={handleSendOTP}
                                    className="SignUpBtn"
                                    sx={{
                                      mt: 4,
                                      textTransform: "capitalize",
                                      color: "white",
                                    }}
                                  >
                                    {t("VerifyEmail")}
                                  </Button>
                                  {errorMessage.email && (
                                    <Typography
                                      variant="body2"
                                      sx={{ color: "red", mt: 1 }}
                                    >
                                      {errorMessage.email}
                                    </Typography>
                                  )}
                                </Box>
                              ) : (
                                // <Box sx={{ py: 2 }}>
                                //   <FormLabel sx={{ display: "block", textAlign: "start", py: 1, fontSize: 14 }}>
                                //     OTP
                                //   </FormLabel>
                                //   <TextField
                                //     variant="outlined"
                                //     sx={{ width: 500, maxWidth: 400 }}
                                //     placeholder="Enter OTP"
                                //     value={otp}
                                //     onChange={(e) => setOtp(e.target.value)}
                                //   />
                                //   <Button onClick={handleVerifyOTP} sx={{ mt: 2 }}>
                                //     Verify OTP
                                //   </Button>
                                // </Box>
                                <Box sx={{ p: 8, pl: 4 }}>
                                  <Box>
                                    <Typography
                                      variant="h5"
                                      sx={{
                                        textAlign: "start",
                                        fontWeight: 700,
                                      }}
                                    >
                                      {t("OTP")}{" "}
                                    </Typography>
                                    <p className="beginText">
                                      We have sent an OTP to your email !{" "}
                                    </p>
                                  </Box>

                                  <Box sx={{ pt: 3 }}>
                                    <OTPInput
                                      inputStyle={{
                                        border: "2px solid #0B3013",
                                        borderRadius: "8px",
                                        width: "54px",
                                        height: "54px",
                                        fontSize: "18px",
                                        color: "#000",
                                        fontWeight: "400",
                                        caretColor: "blue",
                                      }}
                                      focusStyle={{
                                        border: "1px solid #CFD3DB",
                                        outline: "none",
                                      }}
                                      value={otp}
                                      onChange={setOtp}
                                      numInputs={6}
                                      inputType="password"
                                      renderSeparator={
                                        <span
                                          style={{
                                            paddingLeft: 5,
                                            paddingRight: 5,
                                          }}
                                        ></span>
                                      }
                                      renderInput={(props) => (
                                        <input
                                          {...props}
                                          className="otpInput"
                                        />
                                      )}
                                    />
                                    {otpError && (
                                      <Typography
                                        variant="body2"
                                        sx={{ color: "red", mt: 1 }}
                                      >
                                        {otpError}
                                      </Typography>
                                    )}
                                  </Box>

                                  <Box sx={{ pt: 5 }}></Box>
                                  <Box sx={{ mt: 4 }}>
                                    <Button
                                      onClick={handleVerifyOTP}
                                      className="SignUpBtn"
                                      sx={{
                                        textTransform: "capitalize",
                                        width: 100,
                                        color: "white",
                                      }}
                                    >
                                      {t("Verify")}
                                    </Button>
                                  </Box>
                                  {/* <Button
                                    onClick={() => setStep("sendOTP")}
                                    sx={{ mt: 2, ml: 2 }}
                                  >
                                    {t("BackButton")}
                                  </Button> */}
                                  <Box sx={{ my: 1 }}>
                                    <FormLabel
                                      sx={{
                                        display: "flex",
                                        textAlign: "center",
                                        py: 1,
                                        alignItems: "center",
                                        justifyContent: "center",
                                        fontSize: 14,
                                      }}
                                    >
                                      <LockOutlinedIcon
                                        fontSize="12"
                                        sx={{ px: 1 }}
                                      />
                                      {t("YourInfo")}
                                    </FormLabel>
                                  </Box>
                                </Box>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                )}
              </>
            )}

            {value === 4 && (
              <>
                <Typography variant="h4" sx={{ fontWeight: 600 }}>
                  <div>{t("wallet")}</div>
                </Typography>
                <Grid
                  sx={{
                    borderTop: "1px solid #E9E9E9",
                    my: 2,
                  }}
                  xs={12}
                >
                  <Box>
                    <Box sx={{ py: 1 }}>
                      <Typography variant="h6" sx={{ fontWeight: 600 }}>
                        {t("Balance")}: $
                        {!isNaN(userDetails?.wallet)
                          ? userDetails?.wallet?.toFixed(2)
                          : 0}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      mt: 1,
                      display: "flex",
                      justifyContent: "start",
                      gap: "20px",
                    }}
                  >
                    <Button
                      onClick={() => setWithdrawOpen(true)}
                      className="SignUpBtn"
                      sx={{
                        textTransform: "capitalize",
                        py: "8px !important",
                        px: "24px !important",
                        width: "auto !important",
                        borderRadius: 12,
                        maxWidth: "auto !important",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        ml: "-5px",
                      }}
                      endIcon={
                        <Box
                          component="img"
                          src={arrowCross}
                          sx={{ width: 24, height: 24, marginRight: 1 }}
                        />
                      }
                    >
                      {t("withdraw")}
                    </Button>
                  </Box>
                </Grid>
              </>
            )}
          </Box>
        </Box>
      </section>
    </>
  );
}
